
<div class="container-fluid">

  <mat-card class="asd-card">

    <mat-card-header>

      <mat-card-title>
        <h1>{{title}}</h1>
      </mat-card-title>
      <hr>
    </mat-card-header>

    <mat-card-content>
      <ng-template [ngIf]="true" class="container-fluid statistic-count">
        <div class="card card-query">
          <div class="card-header">
            <b>Search Parameter(s)</b>
          </div>
          <div class="card-body">
            <div class="form-row row">
              <mat-form-field appearance="fill" class="col-md-3">
                <mat-label>Date range(To and From)</mat-label>
                <mat-date-range-input [formGroup]="dateRangeReport" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply (click)="loadReportData()">Apply</button>
                  </mat-date-range-picker-actions>
                  <mat-error *ngIf="dateRangeReport.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                  <mat-error *ngIf="dateRangeReport.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-date-range-picker>

              </mat-form-field>


            </div>
          </div>
        </div>


        <div class="card">

          <div class="card-header primary-header">
            <div class="row text-ce">
              <div class="col-sm-6"><b>Report Data</b> </div>
              <div class="col-sm-6 text-end">
                <button mat-raised-button class="btn btn-outline-primary " (click)="onDownload()" matTooltip="Click to download current grid view data" [disabled]="!hasGridData">Download</button>
              </div>
            </div>
          </div>


          <div class="card-body card-body-usda-table2">
            <div class="table-responsive wrapper" [hidden]="!hasGridData">
              <div class="row wrapper">
                <div class="col-md-12">
                  <div id="myChart" class="myChart ag-theme-alpine my-chart" style="width: auto; height: 450px;"></div>
                </div>
                <div class="col-md-12">
                  <ag-grid-angular id="myGrid" style="width: auto; height: 450px;"
                                   class="ag-theme-alpine"
                                   [rowData]="rowData"
                                   [columnDefs]="columnDefs"
                                   [gridOptions]="gridOptions"
                                   [defaultColDef]="defaultColDef"
                                   (gridReady)="onGridReady($event)"
                                   [autoGroupColumnDef]="autoGroupColumnDef"
                                   (firstDataRendered)="onFirstDataRendered($event)"
                                   (modelUpdated)="onModelUpdated($event)"
                                   [animateRows]="true">
                  </ag-grid-angular>
                </div>
              </div>
            </div>

            <div>
              @if (message && !hasGridData){
              {{message}}
              }
              @else if (!message && !hasGridData){
              Please use search input and click search button to load report.
              }
            </div>

          </div>
        </div>

      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
