<div class="card">
  <div class="card-header">
    <h4>Consequences by Occurrence Type Report</h4>
  </div>
  <div class="card-body">
    <div>
      <ag-grid-angular id="grid" style="width: auto; height: 450px;"
                       class="ag-theme-alpine"
                       [rowData]="resultCons"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="ColDef"
                       (gridReady)="onGridReady($event)"
                       [animateRows]="true">
      </ag-grid-angular>

     
    </div>
  </div>
</div>

