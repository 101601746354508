import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AuthorizeService } from "../../api-authorization/authorize.service";
import { FAALocalStorageService } from "./localStorage.service";

@Injectable({
  providedIn: 'root'
})
export class QueryService {
  private apiEndpoint = 'api/Query/';
  constructor(private http: HttpClient, private authenticationService: AuthorizeService, private localStorageService: FAALocalStorageService) {

  }

  public get(id: number, type: any): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'Get/' + id + '/' + type);
  }
  public getAll(username: string, type: any): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'GetAll/' + username + '/' + type);
  }
  public add(vm: any): Observable<any> {

    return this.http.post<any>(this.apiEndpoint + 'Add/', vm);
  }
  public update(vm: any): Observable<any> {
    return this.http.put<any>(this.apiEndpoint + 'Update/', vm);
  }
  public delete(id: number, username: string, type: any): Observable<any> {
    let vm = { id: id, username: username, type: type }
    return this.http.post<any>(this.apiEndpoint + 'Delete/' , vm);
  }
}
