<!--<ag-charts-angular style="position: absolute; top: 0; right: 0; bottom: 0; left: 0;" [options]="">
</ag-charts-angular>-->
<div class="card">
  <div class="card-header">
    <h4>Incident Statistical Report</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div id="p139Chart" class="col" style="flex: 1 1 auto; overflow:visible; height: 50%; width:50%"></div>
      <div id="non139Chart" class="col" style="flex: 1 1 auto; overflow: visible; height: 50%; width: 50%"></div>
    </div>
    <div class="row">
      <div id="p139vsnonp139Chart" class="col" style="flex: 1 1 auto; overflow:visible; height: 50%; width:50%"></div>
      <div id="partsChart" class="col" style="flex: 1 1 auto; overflow: visible; height: 50%; width: 50%"></div>
    </div>
    <div class="row">
      <div id="detailsChart" class="col" style="flex: 1 1 auto; overflow: visible; height: 450px; width: 30%"></div>
      <div class="col" style="padding-top: 120px;">
        <table>
          <tr>
            <td><p style="font-size:large; font-weight:bold; margin-left:10px; margin-right:10px;">Injury Severity</p></td>
            <td><p style="font-size: large; font-weight: bold; margin-left: 5px; margin-right: 5px;">Count</p></td>
          </tr>
          <tr *ngFor="let injury of resultInjury;">
            <td>{{ injury.type }}</td>
            <td>{{ injury.value }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row" style="height:400px;">
      <div class="col">
        <div id="ARFChart" class="col" style="flex: 1 1 auto; overflow: visible; height: 50%; width: 50%"></div>
      </div>
    </div>
    <div style="display: none">
      <ag-grid-angular id="p139Grid" style="width: auto; height: 450px;"
                       class="ag-theme-alpine"
                       [rowData]="result139"
                       [columnDefs]="p139_columnDefs"
                       [defaultColDef]="p139_ColDef"
                       (gridReady)="onp139GridReady($event)"
                       [animateRows]="true"
                       (modelUpdated)="onp139ModelUpdated($event)">
      </ag-grid-angular>

      <ag-grid-angular id="non139Grid" style="width: auto; height: 450px;"
                       class="ag-theme-alpine"
                       [rowData]="resultnon139"
                       [columnDefs]="non139_columnDefs"
                       [defaultColDef]="non139_ColDef"
                       (gridReady)="onnon139GridReady($event)"
                       [animateRows]="true"
                       (modelUpdated)="onnon139ModelUpdated($event)">
      </ag-grid-angular>

      <ag-grid-angular id="detailsGrid" style="width: auto; height: 450px; width: 60%"
                       class="ag-theme-alpine"
                       [rowData]="resultdetails"
                       [columnDefs]="details_columnDefs"
                       [defaultColDef]="details_ColDef"
                       (gridReady)="ondetailsGridReady($event)"
                       [animateRows]="true"
                       (modelUpdated)="ondetailsModelUpdated($event)">
      </ag-grid-angular>
    </div>
  </div>
  </div>
