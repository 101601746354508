import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AuthorizeService } from "../../api-authorization/authorize.service";
import { FAALocalStorageService } from "./localStorage.service";

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  private apiEndpoint = 'api/Service/';
  airports: any = {};
  constructor(private http: HttpClient, private authenticationService: AuthorizeService, private localStorageService: FAALocalStorageService) {

  }
  public getAirportNameWithCodeList(): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'GetAirportNameWithCodeList');
  }
  public getRegions(): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'GetRegions');
  }
  public getRunwayByLocID(loc_ID: any = ''): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'GetRunwayByLocID?loc_ID=' + loc_ID);
  }
  public GetSurfaceTypeConditions(): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'GetSurfaceTypeConditions' );
  }
}
