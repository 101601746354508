import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AuthorizeService } from "../../api-authorization/authorize.service";
import { FAALocalStorageService } from "./localStorage.service";


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiEndpoint = 'api/Report/';
  constructor(private http: HttpClient, private authenticationService: AuthorizeService, private localStorageService: FAALocalStorageService) {

  }
  public getReportColumns(type: any): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'GetReportColumns?type=' + type);
  }
  public getReportData(type: any, query: any, keyword: any = '', maxRows: number = 0, id: any = '', startDate: any = '', endDate: any = ''): Observable<any> {
    if (!maxRows)
      maxRows = 0;
    query = encodeURIComponent(query);
    return this.http.get<any>(this.apiEndpoint + 'GetReportData?type=' + type + '&q=' + query + '&keyword=' + keyword +
      '&maxRows=' + maxRows + '&id=' + id + '&startDate=' + startDate + '&endDate=' + endDate);
  }

  public getCustomizeDownload(vm: any): Observable<any> {

    return this.http.post<any>(this.apiEndpoint + 'GetCustomizeExcelData/', vm);
  }

  public getAirportDiagramByIncidentDate(code: any, date: any): Observable<any> {

    return this.http.get<any>(this.apiEndpoint + 'GetAirportDiagramByIncidentDate?code=' + code + '&date=' + date);
  }

  public UpdateIncidentReportById(vm: any): Observable<any> {

    return this.http.put<any>(this.apiEndpoint + 'UpdateIncidentReportById/', vm);
  }

  public getIncidentReportById(id: any): Observable<any> {

    return this.http.get<any>(this.apiEndpoint + 'GetIncidentReportById?id=' + id);
  }

  //Not sending anything yet so null is set as ReportJSON
  public getStatisticsReport(reportJSON: any): Observable<any> {
    return this.http.post<any>(this.apiEndpoint + 'GetStatisticsReport/', reportJSON);
  }

}
