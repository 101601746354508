<h2 mat-dialog-title>{{title}}</h2>
<mat-divider></mat-divider>


<ng-template [ngIf]="type == 'LOGIN' ">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="loginForm" class="full-width-form">
      <mat-form-field class="full-width-field">
        <mat-label>Username</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>
      <mat-error *ngIf="loginForm.get('email').hasError('required')">
        Username is <strong>required</strong>
      </mat-error>

      <mat-form-field class="full-width-field" *ngIf="hasPassCode">
        <mat-label>Passcode</mat-label>
        <input matInput formControlName="passcode">
      </mat-form-field>
      <mat-error *ngIf="loginForm.get('passcode').hasError('required')">
        Passcode is <strong>required</strong>
      </mat-error>

      <section *ngIf="!hasForgotPassword">
        <mat-form-field class="full-width-field">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password">
        </mat-form-field>
        <mat-error *ngIf="loginForm.get('password').hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </section>

      <section *ngIf="hasPassCode">
        <mat-form-field class="full-width-field">
          <mat-label>New Password</mat-label>
          <input matInput type="password" formControlName="password" (blur)="message = '';">
        </mat-form-field>

        <div class="password-rule" style="font-size:smaller;">

          <label class="col" [ngClass]="loginForm.get('password').value === ''  ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="loginForm.get('password').value !== '' ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="loginForm.get('password').value === ''  ">highlight_off</mat-icon>
            New Password is <strong>required</strong>
          </label>
          <label class="col" [ngClass]=" !validPasswordRule('UPPER', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('UPPER', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('UPPER', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>upper case</strong> letter
          </label>

          <label class="col" [ngClass]=" !validPasswordRule('LOWER', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('LOWER', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('LOWER', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>lower case</strong> letter
          </label>

          <label class="col" [ngClass]=" !validPasswordRule('SPECIAL', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('SPECIAL', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('SPECIAL', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>special</strong> character
          </label>

          <label class="col" [ngClass]=" !validPasswordRule('NUMBER', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('NUMBER', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('NUMBER', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>numeric</strong> character
          </label>
          <label class="col" [ngClass]=" !validPasswordRule('MIN_LENGTH', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('MIN_LENGTH', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('MIN_LENGTH', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must be at least <strong>8 characters</strong>

          </label>
        </div>

        <mat-form-field class="full-width-field">
          <mat-label>Confirm New Password</mat-label>
          <input matInput type="password" formControlName="newPassword">
        </mat-form-field>
        <mat-error *ngIf="loginForm.get('newPassword').hasError('required')">
          Confirm New Password is <strong>required</strong>
        </mat-error>

        <div *ngIf="(loginForm.get('password').value !== '' && loginForm.get('newPassword').value !== '') && (loginForm.get('newPassword').value  !== loginForm.get('password').value)">

          <label class="col" [ngClass]=" loginForm.get('password').value  !== loginForm.get('newPassword').value ? 'text-danger' :'text-success'">
            <i [ngClass]="loginForm.get('password').value  !== loginForm.get('newPassword').value  ? 'fas fa-times-circle' :'far fa-check-circle'" aria-hidden="true"></i>
            Password did not <strong>matched</strong>
          </label>
        </div>
      </section>


      <section *ngIf="!hasForgotPassword">
        <a mat-button (click)="onForgotPasswordToggle()" class="btn-link" style="font-size: small">Forgot password?</a>
      </section>
      <section *ngIf="hasForgotPassword && !hasPassCode">
        <a mat-button (click)="onHasPassCode()" class="btn-link" style="font-size: small">Already have a Passcode?</a>
      </section>

    </form>

  </mat-dialog-content>

  <mat-divider></mat-divider>
  <div class="alert" [ngClass]="alertType == 'success'  ? 'alert-success' : 'alert-danger' " style="margin-top: 5px; margin-bottom: 0px;" role="alert" *ngIf="message">
    <div [innerHTML]="message"></div>
  </div>
  <mat-dialog-actions align="end" class="full-width-field">
    <a mat-button *ngIf="hasForgotPassword" (click)="onBackToLogin()" class="btn-link" style="font-size: small">Back to Login</a>

    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button *ngIf="!hasForgotPassword" color="primary" [disabled]="!loginForm.valid" (click)="onLogin()" cdkFocusInitial>Login</button>
    <button mat-raised-button *ngIf="hasForgotPassword &&  !hasPassCode " color="primary" [disabled]="!loginForm.valid" (click)="onSendPassCode()" cdkFocusInitial>Send Passcode</button>
    <button mat-raised-button *ngIf="hasPassCode" color="primary" [disabled]="!hasValidPasswordAndForm()" (click)="onForgotPassword()" cdkFocusInitial>Update Password</button>
  </mat-dialog-actions>
</ng-template>



<ng-template [ngIf]="type == 'CHANGE_PASSWORD' ">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="loginForm" class="full-width-form">

      <section>
        <mat-form-field class="full-width-field">
          <mat-label>Current Password</mat-label>
          <input matInput type="password" formControlName="password">
        </mat-form-field>
        <mat-error *ngIf="loginForm.get('password').hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </section>

      <section>
        <mat-form-field class="full-width-field">
          <mat-label>New Password</mat-label>
          <input matInput type="password" formControlName="newPassword" (blur)="message = '';">
        </mat-form-field>

        <div class="password-rule" style="font-size:smaller;">

          <label class="col" [ngClass]="loginForm.get('newPassword').value === ''  ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="loginForm.get('newPassword').value !== '' ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="loginForm.get('newPassword').value === ''  ">highlight_off</mat-icon>
            New Password is <strong>required</strong>
          </label>
          <label class="col" [ngClass]=" !validPasswordRule('UPPER', loginForm.get('newPassword').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('UPPER', loginForm.get('newPassword').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('UPPER', loginForm.get('newPassword').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>upper case</strong> letter
          </label>

          <label class="col" [ngClass]=" !validPasswordRule('LOWER', loginForm.get('newPassword').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('LOWER', loginForm.get('newPassword').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('LOWER', loginForm.get('newPassword').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>lower case</strong> letter
          </label>

          <label class="col" [ngClass]=" !validPasswordRule('SPECIAL', loginForm.get('newPassword').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('SPECIAL', loginForm.get('newPassword').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('SPECIAL', loginForm.get('newPassword').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>special</strong> character
          </label>

          <label class="col" [ngClass]=" !validPasswordRule('NUMBER', loginForm.get('newPassword').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('NUMBER', loginForm.get('newPassword').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('NUMBER', loginForm.get('newPassword').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>numeric</strong> character
          </label>
          <label class="col" [ngClass]=" !validPasswordRule('MIN_LENGTH', loginForm.get('newPassword').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('MIN_LENGTH', loginForm.get('newPassword').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('MIN_LENGTH', loginForm.get('newPassword').value) ">highlight_off</mat-icon>
            Must be at least <strong>8 characters</strong>

          </label>
        </div>

        <mat-form-field class="full-width-field">
          <mat-label>Confirm New Password</mat-label>
          <input matInput type="password" formControlName="confirmPassword">
        </mat-form-field>
        <mat-error *ngIf="loginForm.get('confirmPassword').hasError('required')">
          Confirm New Password is <strong>required</strong>
        </mat-error>

        <div *ngIf="(loginForm.get('newPassword').value !== '' && loginForm.get('confirmPassword').value !== '') && (loginForm.get('newPassword').value  !== loginForm.get('confirmPassword').value)">

          <label class="col" [ngClass]=" loginForm.get('newPassword').value  !== loginForm.get('confirmPassword').value ? 'text-danger' :'text-success'">
            <i [ngClass]="loginForm.get('newPassword').value  !== loginForm.get('confirmPassword').value  ? 'fas fa-times-circle' :'far fa-check-circle'" aria-hidden="true"></i>
            Password did not <strong>matched</strong>
          </label>
        </div>
      </section>


    </form>

  </mat-dialog-content>

  <mat-divider></mat-divider>
  <div class="alert" [ngClass]="alertType == 'success'  ? 'alert-success' : 'alert-danger' " style="margin-top: 5px; margin-bottom: 0px;" role="alert" *ngIf="message">
    <div [innerHTML]="message"></div>
  </div>
  <mat-dialog-actions align="end" class="full-width-field">
  
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button *ngIf="!hasForgotPassword" color="primary" [disabled]="!loginForm.valid" (click)="onChangePassword()" cdkFocusInitial>Change Password</button>
  </mat-dialog-actions>
</ng-template>


