import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { FAALocalStorageService } from '../../../services/localStorage.service';
import { ReportService } from '../../../services/report.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'app-column-filter-view',
  templateUrl: './column-filter-view.component.html',
  styleUrls: ['./column-filter-view.component.css']
})
export class ColumnFilterViewComponent implements OnInit {
  @Input() showSideBarColumnFilterView = false;
  @Input() type = '';
  @Input() columns: any = {};
  @Input() sqlQuery: any = {};
  @Output() onCloseSideBarColumnFilterView = new EventEmitter();
  filterColumns: any = [];
  checkedAll: boolean = true;
  totalColumns: number = 0;
  result: any = {}
  rowData: any[] | null;
  alertType: string = 'success';
  message: string = '';
  hasGridData: boolean = false;

  gridApi;
  gridColumnApi;
  columnDefs = [
    { field: 'merged_data_source_id' },
  ];
  gridOptions = {
    columnDefs: this.columnDefs,

  };
  columnDefsNarrative = [

    //{ field: 'merged_data_source_id', headerName: 'Incident ID', sortable: true, filter: true },
    { field: 'asrs_rpt1_narrative', headerName: 'ASRS Report 1 Narrative', sortable: true, filter: true },
    { field: 'asrs_rpt2_narrative', headerName: 'ASRS Report 2 Narrative', sortable: true, filter: true },
    { field: 'pds_corr_rmk_text', headerName: 'PDS Corrected Narrative', sortable: true, filter: true },
    { field: 'rws_rwy_sfty_narrative', headerName: 'RWS Runway Safety Narrative', sortable: true, filter: true },
    { field: 'vpd_rmk_text_inves', headerName: 'VPD Investigation', sortable: true, filter: true },
    { field: 'ntsb_rprt_narr_cause_text', headerName: 'NTSB Cause', sortable: true, filter: true },
    { field: 'ntsb_rprt_narr_final_text', headerName: 'NTSB Final', sortable: true, filter: true },
    { field: 'aids_remarks_text', headerName: 'AIDS Remarks Text', sortable: true, filter: true }
  ];
  columnDefsAircraft = [
    /*{ field: 'merged_data_source_id', headerName: 'Incident ID', sortable: true, filter: true },*/
    { field: 'runway_length', headerName: 'Runway Length', sortable: true, filter: true },
    { field: 'runway_width', headerName: 'Runway Width', sortable: true, filter: true },
    { field: 'runway_excursions_veeroff_distance', headerName: 'Runway Excursions Veeroff Distance', sortable: true, filter: true },
    { field: 'runway_excursions_overrun_distance', headerName: 'Runway Excursions Overrun Distance', sortable: true, filter: true },
    { field: 'taxiway_excursions_veeroff_distance', headerName: 'Taxiway Excursions Veeroff Distance', sortable: true, filter: true },
    { field: 'aircraft_1_make', headerName: 'Aircraft 1 Make', sortable: true, filter: true },
    { field: 'aircraft_1_model', headerName: 'Aircraft 1 Model', sortable: true, filter: true },
    { field: 'aircraft_1_series', headerName: 'Aircraft 1 Series', sortable: true, filter: true },
    { field: 'aircraft_1_registration_no', headerName: 'Aircraft 1 Registration No', sortable: true, filter: true },
    { field: 'aircraft_2_make', headerName: 'Aircraft 2 Make', sortable: true, filter: true },
    { field: 'aircraft_2_model', headerName: 'Aircraft 2 Model', sortable: true, filter: true },
    { field: 'aircraft_2_series', headerName: 'Aircraft 2 Series', sortable: true, filter: true },
    { field: 'aircraft_2_registration_no', headerName: 'Aircraft 2 Registration', sortable: true, filter: true },
    { field: 'notes', headerName: 'Notes', sortable: true, filter: true }
  ];
  constructor(private localStorageService: FAALocalStorageService, private reportService: ReportService,
    private spinnerService: SpinnerService, private messageService: MessageService) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    this.onInit();
  }
  onInit() {
    if (this.showSideBarColumnFilterView) {
      if (Object.entries(this.columns).length > 0)
        this.setAlreadySelectedColumns();

      this.totalColumns = Object.entries(this.columns).length - 1 + (this.columnDefsAircraft.length + this.columnDefsNarrative.length);;
    }
  }
  onHide() {
    this.showSideBarColumnFilterView = false;
    let vm = { status: 'CLOSE', data: null };
    this.onCloseSideBarColumnFilterView.emit(false);
  }
  onDownload() {
    //save filterd coluns in local storage for next export
    let keyid = 'asd-exportColumns-' + this.type;
    this.localStorageService.set(keyid, this.filterColumns);
    if (this.checkedAll)
      this.localStorageService.remove(keyid); // check all will not save to local storage

    let colGrid = this.columns.filter(i => this.filterColumns.indexOf(i.headerName) !== -1);
    let colGridNarrative = this.columnDefsNarrative.filter(i => this.filterColumns.indexOf(i.headerName) !== -1);
    let colGridAircraft = this.columnDefsAircraft.filter(i => this.filterColumns.indexOf(i.headerName) !== -1);

    const f = [].concat(colGrid, colGridNarrative, colGridAircraft);

    let cols = f.map(c => c.field);
    let _cols = cols.toString().replace('aids_remarks_text', 'aid_rmk_text');
    _cols = _cols.toString().replace('non_movement_area_event', 'non_movement_area_events');
  //  _cols = _cols.toString().replace('date_of_incident', 'CONVERT(nvarchar(10), date_of_incident, 23) as date_of_incident');

    this.columnDefs = f;
    let vm = {
      q: this.sqlQuery,
      c: _cols
    };

    this.rowData = null;
    this.hasGridData = false;
    this.result = {};
    this.spinnerService.show();

    this.reportService.getCustomizeDownload(vm)
       .subscribe({
    next: (result) => {
           this.spinnerService.hide();
           let _result: any = {};
           _result = result;
           this.hasGridData = _result.total > 0;
           if (_result.success) {
             this.rowData = _result.result;
             this.message = _result.total > 0 ? '' : 'No data found.';

             if (_result.total == 0)
               this.showStatusMessage('Data Loaded Successfully', 'Download', 'success');
             else
               this.generateExcel();
           }
           else {
             this.alertType = 'danger';
             let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
             this.message = 'Error while loading data. ' + _errorInfo;
             this.showStatusMessage(this.message, 'Download', 'error');
           }
    },

     error: (err: any) => {
       let _err: any = {};
       _err = err;
       this.alertType = 'danger';
       let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
       this.message = 'Error while loading data. ' + _err.errormessage;
       this.showStatusMessage(this.message, 'Download', 'error');
       this.spinnerService.hide();
        },
    complete: () => { }
  }
);

  }
  generateExcel() {
    setTimeout(() => {
      let d = new Date();
      this.gridApi.exportDataAsCsv({
        fileName: this.type + '_Combined_Data_' + (d.getMonth() + 1) + d.getDate() + d.getFullYear(),
        /*  columnKeys: f.map(column => column.field)*/
      });
      //pass the filted list of columns for export to EXCEL
      let vm = { status: 'OK', data: this.filterColumns };
      this.onCloseSideBarColumnFilterView.emit(vm);
    }, 10);
  }
  setAlreadySelectedColumns() {
    // get saved colums from local storage
    let keyid = 'asd-exportColumns-' + this.type;
    let _item = this.localStorageService.get(keyid) || [];

    if (Object.keys(_item).length > 0) {
      this.checkedAll = false;
      this.filterColumns = _item;
    }
    else {
      this.checkedAll = true;
      this.onSelectAll();
    }

  }
  onSelectAll() {

    if (this.checkedAll) {
      this.filterColumns = [];
      for (let item of Object.entries(this.columns)) {
        if (item[1]?.['headerName'] !== '')
          this.filterColumns.push(item[1]?.['headerName']);
      }
      for (let item of Object.entries(this.columnDefsNarrative)) {
        if (item[1]?.['headerName'] !== '')
          this.filterColumns.push(item[1]?.['headerName']);
      }
      for (let item of Object.entries(this.columnDefsAircraft)) {
        if (item[1]?.['headerName'] !== '')
          this.filterColumns.push(item[1]?.['headerName']);
      }
    }
    else {
      this.filterColumns = [];
    }


  }
  setSelectAllChecked() {
    //if mtches total count then checked - select all
    if (this.filterColumns)
      this.checkedAll = this.totalColumns == this.filterColumns?.length;
  }
  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity == '' ? 'success' : 'error';
    this.messageService.add({ severity: severity, summary: action, detail: message });
  }
  onGridReady(params) {

    this.gridApi = params.api;
  
  }

}
