export interface ReturnViewModel {
  Success: string;
  Result: any;
  ErrorMessage: string;
  Total: number
}
export enum PasswordRuleType {
  UpperCase,
  LowerCase,
  Number,
  SpecialCharacter
}

export interface AirportModel{
  name: string;
  id: number;
  code: string;
}


export interface Field {
  name: string;
  value?: string;
  type: string;
  nullable?: boolean;
  options?: Option[];
  operators?: string[];
  defaultValue?: any;
  defaultOperator?: any;
  entity?: string;
  
}
export interface FieldMap {
  [key: string]: Field;
}


export interface Option {
  name: string;
  value: any;
}

export interface RuleSet {
  condition: string;
  rules: Array<RuleSet | Rule>;
  collapsed?: boolean;
  isChild?: boolean;
}

export interface Rule {
  field: string;
  value?: any;
  operator?: string;
  entity?: string;
}

export interface Entity {
  name: string;
  value?: string;
  defaultField?: any;
}

export interface EntityMap {
  [key: string]: Entity;
}
export interface QueryBuilderConfig {
  fields: FieldMap;
  entities?: EntityMap;
  allowEmptyRulesets?: boolean;
  getOperators?: (fieldName: string, field: Field) => string[];
  getInputType?: (field: string, operator: string) => string;
  getOptions?: (field: string) => Option[];
  addRuleSet?: (parent: RuleSet) => void;
  addRule?: (parent: RuleSet) => void;
  removeRuleSet?: (ruleset: RuleSet, parent: RuleSet) => void;
  removeRule?: (rule: Rule, parent: RuleSet) => void;
  coerceValueForOperator?: (operator: string, value: any, rule: Rule) => any;
  calculateFieldChangeValue?: (currentField: Field,
    nextField: Field,
    currentValue: any) => any;
}


export interface IngestChartModel {
  ingest_date: string;
  type: number;
  count: string;
}
