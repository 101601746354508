
<div class="container-fluid">

  <mat-card class="asd-card">

    <mat-card-header>

      <mat-card-title>
        <h1>{{title}}</h1>

      </mat-card-title>
      <hr>
    </mat-card-header>

    <mat-card-content>
      <div class="row  da-row-0">
        <div class="card faa-margin-lr col-sm bg-success text-white">
          <div class="card-body">
            <div>
              <h4 class="rate-analytic">{{setIngestStatistic('MDS')}}</h4>
              <p class="analytic-title">MDS</p>
            </div>
            <div class="mt-3 analytic-line-chart">
              <i class="pi pi-chart-line" style="font-size: 2rem"></i>
            </div>
          </div>
        </div>
        <div class="card faa-margin-lr col-sm bg-primary text-white">
          <div class="card-body">
            <div>
              <h4 class="rate-analytic">{{setIngestStatistic('AIRPORT')}}</h4>
              <p class="analytic-title">AIRPORTS</p>
            </div>
            <h4 class="rate-analytic">{{setIngestStatistic('AIRPORT_DIAGRAM')}}</h4>
            <p class="analytic-title text-uppercase">Diagrams</p>
            <!--<div class="mt-3 analytic-line-chart">
              <i class="pi pi-chart-bar" style="font-size: 2rem"></i>

            </div>-->
          </div>
        </div>
        <div class="card faa-margin-lr col-sm bg-secondary text-white">
          <div class="card-body">
            <div>
              <h4 class="rate-analytic">{{setIngestStatistic('NTSB')}}</h4>
              <p class="analytic-title text-uppercase">NTSB</p>
            </div>
            <div class="mt-3 analytic-line-chart">
              <i class="pi pi-chart-line" style="font-size: 2rem"></i>
            </div>
          </div>
        </div>
        <div class="card faa-margin-lr col-sm bg-warning text-white">
          <div class="card-body">
            <div>
              <h4 class="rate-analytic">{{setIngestStatistic('PDS')}}</h4>
              <p class="analytic-title text-uppercase">PDS</p>
            </div>
            <div class="mt-3 analytic-line-chart">
              <i class="pi pi-chart-pie" style="font-size: 2rem"></i>
            </div>
          </div>
        </div>

        <div class="card faa-margin-lr col-sm bg-danger text-white">
          <div class="card-body">
            <div>
              <h4 class="rate-analytic">{{setIngestStatistic('RWS')}}</h4>
              <p class="analytic-title text-uppercase">RWS</p>
            </div>
            <div class="mt-3 analytic-line-chart">
              <i class="pi pi-chart-line" style="font-size: 2rem"></i>
            </div>
          </div>
        </div>

        <div class="card faa-margin-lr col-sm bg-success-light text-white">
          <div class="card-body">
            <div>
              <h4 class="rate-analytic">{{setIngestStatistic('VPDS')}}</h4>
              <p class="analytic-title text-uppercase">VPDS</p>
            </div>
            <div class="mt-3 analytic-line-chart">
              <i class="pi pi-chart-pie" style="font-size: 2rem"></i>
            </div>
          </div>
        </div>

        <div class="card faa-margin-lr col-sm bg-primary-light text-white">
          <div class="card-body">
            <div>
              <h4 class="rate-analytic">{{setIngestStatistic('ASRS')}}</h4>
              <p class="analytic-title text-uppercase">ASRS</p>
            </div>
            <div class="mt-3 analytic-line-chart">
              <i class="pi pi-chart-bar" style="font-size: 2rem"></i>
            </div>
          </div>
        </div>

        <div class="card faa-margin-lr col-sm bg-danger-light text-white">
          <div class="card-body">
            <div>
              <h3 class="rate-analytic">{{setIngestStatistic('AIDS')}}</h3>
              <p class="analytic-title text-uppercase">AIDS</p>
            </div>
            <div class="mt-3 analytic-line-chart">
              <i class="pi pi-chart-line" style="font-size: 2rem"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row da-row-2">
        <div class="card faa-margin-lr col-sm">
          <div class="card-body">
            <div>
              <span class="h3 analytic-title text-uppercase form-inline">
                Ingest Cycle Statistics:&nbsp; &nbsp;
                <mat-form-field>
                  <mat-label>Type</mat-label>
                  <mat-select [(value)]="selectedType" (selectionChange)="getIngestStatisticData()" matTooltip="Select Type">
                    <mat-option value="MDS" *ngIf="!hasIngestStatisticResult">MDS</mat-option>
                    <ng-template [ngIf]="hasIngestStatisticResult">
                      <mat-option *ngFor="let item of ingestTypes" [value]="item.type">{{item.type}}</mat-option>
                    </ng-template>

                  </mat-select>
                </mat-form-field>
                <button mat-icon-button color="primary" aria-label="Export to Excel" [hidden]="false" (click)="onDownload()" matTooltip="Export to Excel">
                  <i class="pi pi-file-excel" style="color: green; font-size: 1.5rem"></i>
                </button>
              </span>

            </div>
            <div class="analytic-line-chart">
              <ag-charts style="height: 100%"
                                 [options]="optionsIngestStatistics"></ag-charts>
            </div>
          </div>
        </div>

        <div class="card faa-margin-lr col-sm">
          <div class="card-body">
            <div>

              <h3 class="analytic-title text-uppercase">Incident Statistics</h3>
            </div>
            <div class="analytic-line-chart">
              <ag-charts style="height: 100%"
                                 [options]="optionsIncidentStatistics"></ag-charts>
            </div>
          </div>
        </div>
      </div>

      <div class="row da-row-3">

      </div>

    </mat-card-content>
  </mat-card>
</div>

<ag-grid-angular style="width: 0; height: 0;" [hidden]="true"
                 class="ag-theme-alpine"
                 [columnDefs]="columnDefs"
                 [rowData]="rowData"
                 (gridReady)="onGridReady($event)"></ag-grid-angular>
