
<div class="container-fluid">

  <mat-card class="asd-card">

    <mat-card-header>

      <mat-card-title>
        <h1>{{title}}</h1>
      </mat-card-title>
      <hr>
    </mat-card-header>

    <mat-card-content>

      <ng-template class="container-fluid non-statistic-report" [ngIf]="!hasNonDataSourceReport">

        <!--<div class="alert alert-danger" role="alert" *ngIf="message">
          {{ message}}
        </div>-->
        <div class="card card-query" [hidden]="reportType === 'INCIDENT' || reportType == 'INCIDENT_BY_STATUS'  ">
          <div class="card-header">
            <b>Search Parameters</b>
          </div>
          <div class="card-body">

            <query-builder [(ngModel)]='query' [config]='config' (ngModelChange)="onQueryChange($event)"></query-builder>

          </div>
        </div>

        <div class="card card-query" *ngIf="reportType === 'INCIDENT' && incidentQuery">
         
          <div class="card-header">
            <div class="row">
              <div class="col-md-6">
                <b>Search Parameters <span *ngIf="incidentSearchQuery?.total > 0 " matBadge="{{incidentSearchQuery?.total }}" matBadgeColor="warn" matTooltip="Total filter count">&nbsp;&nbsp;</span></b>
              </div>

              <div class="col-md-6 text-end">
                <button mat-raised-button (click)="accordion.openAll()">Expand All</button>&nbsp;&nbsp;
                <button mat-raised-button (click)="accordion.closeAll()">Collapse All</button>
              </div>
            </div>
          </div>
          <div class="card-body incident-search-form" [formGroup]="incidentQuery">

            <mat-accordion class="incident-report--headers-align" multi *ngIf="hasLoadedInicidentSearchLookupData">

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Date & Time  <span *ngIf="incidentSearchQuery?.dateTime?.length > 0" matBadge="{{incidentSearchQuery?.dateTime?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp; </span>
                  </mat-panel-title>

                </mat-expansion-panel-header>
                <div class="form-row row">
                  <mat-form-field appearance="fill" class="col-md-6">
                    <mat-label>Date range(To and From)</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="dateRangeEvent('start', $event)">
                      <input matEndDate formControlName="end" placeholder="End date" (dateChange)="dateRangeEvent('end', $event)">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error *ngIf="incidentQuery.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="incidentQuery.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Time Of Day  </mat-label>
                    <mat-select multiple formControlName="ambient_light_conditions" (blur)="onFocusOutEvent($event, 'dateTime', 'ambient_light_conditions')">

                      <mat-option *ngFor="let item of timeOfDay" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Local Time Category</mat-label>
                    <mat-select multiple formControlName="local_time_category" (blur)="onFocusOutEvent($event, 'dateTime', 'local_time_category')">
                 
                      <mat-option *ngFor="let item of localTimes" [value]="item.name" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>

                    Airport Information  <span *ngIf="incidentSearchQuery?.airport?.length > 0" matBadge="{{incidentSearchQuery?.airport?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-row row">
                  <input id="airport_code" type="hidden" formControlName="airport_code">
                  @if(hasLoadedAirports){
                  <app-faa-multiselect-autocomplete #airportSelect formControlName="airport_code" class="col-md-6"
                                                    [itemControlName]="'airport_code'"
                                                    [placeholder]="'Select Airport'"
                                                    [key]="'code'"
                                                    [field_label]="'Airport Code/Name'"
                                                    [field_class]="12"
                                                    [allItems]="airports"
                                                    [selectedItems]="selectedAirports"
                                                    (selectionChange)="onSelectChange($event)"
                                                    (blur)="onFocusOutEvent($event, 'airport', 'airport_code')">
                  </app-faa-multiselect-autocomplete>
                  }
                  <mat-form-field appearance="fill" class="col-md-3">

                    <mat-label>FAA Region</mat-label>
                    <mat-select multiple formControlName="region_code" (blur)="onFocusOutEvent($event, 'airport', 'region_code')">

                      <mat-option *ngFor="let item of filteredRegions" [value]="item.code">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>GA Asset Role</mat-label>

                    <mat-select multiple formControlName="ga_asset_role" (blur)="onFocusOutEvent($event, 'airport', 'ga_asset_role')">

                      <mat-option *ngFor="let item of filteredGaAssetRoles" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Airport Use</mat-label>

                    <mat-select multiple formControlName="airport_use" (blur)="onFocusOutEvent($event, 'airport', 'airport_use')">

                      <mat-option *ngFor="let item of filteredAirportUse" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>
                      Airport Ownership
                    </mat-label>

                    <mat-select multiple formControlName="airport_ownership" (blur)="onFocusOutEvent($event, 'airport', 'airport_ownership')">

                      <mat-option *ngFor="let item of filteredAirportOwnership" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>14 CFR Part 139</mat-label>

                    <mat-select multiple formControlName="airport_part_139" (blur)="onFocusOutEvent($event, 'airport', 'airport_part_139')">

                      <mat-option *ngFor="let item of filtered_14CFRPart139" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Towered Airport</mat-label>

                    <mat-select multiple formControlName="towered_airport" (blur)="onFocusOutEvent($event, 'airport', 'towered_airport')">

                      <mat-option *ngFor="let item of filteredToweredAirport" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>NPIAS Hub Size</mat-label>

                    <mat-select multiple formControlName="npias_hub_size" (blur)="onFocusOutEvent($event, 'airport','npias_hub_size')">

                      <mat-option *ngFor="let item of filteredNPIASHubSize" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>NPIAS Service Level</mat-label>

                    <mat-select multiple formControlName="npias_service_level" (blur)="onFocusOutEvent($event, 'airport', 'npias_service_level')">

                      <mat-option *ngFor="let item of filteredNPIASServiceLevel" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>CORE 30</mat-label>

                    <mat-select multiple formControlName="core_30" (blur)="onFocusOutEvent($event, 'airport', 'core_30')">

                      <mat-option *ngFor="let item of filteredCORE30" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>OEP 35</mat-label>

                    <mat-select multiple formControlName="oep_35" (blur)="onFocusOutEvent($event, 'airport', 'oep_35')">

                      <mat-option *ngFor="let item of filteredOEP35" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Location, Weather & Surface Condition  <span *ngIf="incidentSearchQuery?.location?.length > 0" matBadge="{{incidentSearchQuery?.location?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-row row">
                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Location</mat-label>

                    <mat-select multiple formControlName="location_display" (blur)="onFocusOutEvent($event, 'location', 'location_display')">

                      <mat-option *ngFor="let item of filteredLocations" [value]="item.code">{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-2">
                    <mat-label>Weather</mat-label>

                    <mat-select multiple formControlName="vmc_imc" (blur)="onFocusOutEvent($event, 'location', 'vmc_imc')">

                      <mat-option *ngFor="let item of filteredWeathers" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>


                  <mat-form-field appearance="fill" class="col-md-2">
                    <mat-label>Weather Phenomena - Precipitation</mat-label>

                    <mat-select multiple formControlName="weather_display" (blur)="onFocusOutEvent($event, 'location', 'weather_display')">

                      <mat-option *ngFor="let item of filteredPrecipitations" [value]="item.code">{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>


                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Surface Condition</mat-label>

                    <mat-select multiple formControlName="surface_condition_display" (blur)="onFocusOutEvent($event, 'location', 'surface_condition_display')">

                      <mat-option *ngFor="let item of filteredSurfaceConditions" [value]="item.code">{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-2">
                    <mat-label>Surface Type Condition</mat-label>

                    <mat-select multiple formControlName="surface_type_condition_id" (blur)="onFocusOutEvent($event, 'location', 'surface_type_condition_id')">

                      <mat-option *ngFor="let item of filteredSurfaceTypeConditions" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Occurrences <span *ngIf="incidentSearchQuery?.occurrences?.length > 0" matBadge="{{incidentSearchQuery?.occurrences?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="form-row row">
                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Accident or Incident</mat-label>

                    <mat-select multiple formControlName="accident_or_incident" (blur)="onFocusOutEvent($event, 'occurrences', 'accident_or_incident')">
                  
                      <mat-option *ngFor="let item of filteredAccidentOrIncident" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Hot Spot</mat-label>

                    <mat-select multiple formControlName="hot_spot" (blur)="onFocusOutEvent($event, 'occurrences', 'hot_spot')">
                     
                      <mat-option *ngFor="let item of filteredHotSpots" [value]="item.name" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-6">
                    <mat-label>Occurrence Items</mat-label>

                    <mat-select multiple formControlName="occurrence_display" (blur)="onFocusOutEvent($event, 'occurrences', 'occurrence_display')">
                   
                      <mat-option *ngFor="let item of filteredOccurrenceItems" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Ops & Flight Phase <span *ngIf="incidentSearchQuery?.opsFlightPhase?.length > 0" matBadge="{{incidentSearchQuery?.opsFlightPhase?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="form-row row">
                  <mat-form-field appearance="fill" class="col-md-6">
                    <mat-label>Type Of Operations</mat-label>

                    <mat-select multiple formControlName="operation_type_display" (blur)="onFocusOutEvent($event, 'opsFlightPhase', 'operation_type_display')">
                     
                      <mat-option *ngFor="let item of filteredOperationTypes" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-6">
                    <mat-label>Flight Phase</mat-label>

                    <mat-select multiple formControlName="flight_phase_display" (blur)="onFocusOutEvent($event, 'opsFlightPhase', 'flight_phase_display')">
                     
                      <mat-option *ngFor="let item of filteredFlightPhases" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Outcome<span *ngIf="incidentSearchQuery?.outcome?.length > 0" matBadge="{{incidentSearchQuery?.outcome?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="form-row row">
                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Any Injuries</mat-label>
                    <mat-select formControlName="inj_any" (blur)="onFocusOutEvent($event, 'outcome', 'inj_any')">
                      <mat-option *ngFor="let item of yesOptionOnly" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Specific Injuries</mat-label>

                    <mat-select multiple formControlName="inj_specific" (blur)="onFocusOutEvent($event, 'outcome', 'inj_specific')">
                      <mat-option *ngFor="let item of specificInjuries" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-6">
                    <mat-label>NTSB/AIDS - Damage To Aircraft</mat-label>

                    <mat-select multiple formControlName="aircraft_damage" (blur)="onFocusOutEvent($event, 'outcome', 'aircraft_damage')">
                     
                      <mat-option *ngFor="let item of filteredNTSB_AIDSDamageToAircraft" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Any Aircraft Damages</mat-label>
                    <mat-select formControlName="any_aircraft_damages" (blur)="onFocusOutEvent($event, 'outcome', 'any_aircraft_damages')">
                      <mat-option *ngFor="let item of yesOptionOnly" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Damage To Property</mat-label>
                    <mat-select formControlName="property_damage" (blur)="onFocusOutEvent($event, 'outcome', 'property_damage')">
                      <mat-option *ngFor="let item of yesOptionOnly" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>


                  <mat-form-field appearance="fill" class="col-md-6">
                    <mat-label>Unspecified Type(s) of Aircraft Damage (Non-NTSB/AIDS Data Sources)</mat-label>
                    <mat-select formControlName="aircraft_dam_non_ntsb" (blur)="onFocusOutEvent($event, 'outcome', 'aircraft_dam_non_ntsb')">
                      <mat-option *ngFor="let item of yesOptionOnly" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>


              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Misc. Information<span *ngIf="incidentSearchQuery?.miscInfomration?.length > 0" matBadge="{{incidentSearchQuery?.miscInfomration?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="form-row row">
                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Data Source</mat-label>

                    <mat-select multiple formControlName="data_source" (blur)="onFocusOutEvent($event, 'miscInfomration', 'data_source')">
                    
                      <mat-option *ngFor="let item of filteredDataSources" [value]="item.code" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>
                      Category of Runway Incursion
                    </mat-label>
                    
                    <mat-select multiple formControlName="rws_filter"  (blur)="onFocusOutEvent($event, 'miscInfomration', 'rws_filter')">
                   
                      <mat-option *ngFor="let item of filteredRunwayIncursionCategory" [value]="item.code">{{item.name}}</mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-5">
                    <mat-label>
                      Contributing Factors
                    </mat-label>

                    <mat-select multiple formControlName="contributing_factors"  (blur)="onFocusOutEvent($event, 'miscInfomration', 'contributing_factors')">
                      <mat-optgroup>
                        Non-Standard Geometry
                        <mat-option *ngFor="let item of filteredNonStandardGeometry" [value]="item.name">{{item.name}}</mat-option>
                      </mat-optgroup>



                      <mat-optgroup>
                        Construction
                        <mat-option *ngFor="let item of  construction" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>
                      <mat-optgroup>
                        Signage
                        <mat-option *ngFor="let item of  signage" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>
                      <mat-optgroup>
                        Marking
                        <mat-option *ngFor="let item of  marking" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>
                      <mat-optgroup>
                        Lighting
                        <mat-option *ngFor="let item of  lighting" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>


                      <mat-optgroup>
                        Procedures/Operations
                        <mat-option *ngFor="let item of  proceduresOrOperations" [value]="item.name" >{{item.name}}</mat-option>

                      </mat-optgroup>
                      <mat-optgroup>
                        Communication
                        <mat-option *ngFor="let item of  communication" [value]="item.name">{{item.name}}</mat-option>

                      </mat-optgroup>

                      <mat-optgroup>
                        Obstructions near a Runway/Taxiway
                        <mat-option *ngFor="let item of  obstructionsNearRunway" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>
                      <mat-optgroup>
                        Mechanical Failure
                        <mat-option *ngFor="let item of  mechanicalFailure" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>

                      <mat-optgroup>
                        Loss Of Aircraft Control
                        <mat-option *ngFor="let item of  lossOfAircraftControl" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>
                      <mat-optgroup>
                        Weather
                        <mat-option *ngFor="let item of  weather" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>

                      <mat-optgroup>
                        Lack of Overrun Are
                        <mat-option *ngFor="let item of  lackOfOverrunAre" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>
                      <mat-optgroup>
                        Failure to Maintain Clearance from an Object/Aircraft
                        <mat-option *ngFor="let item of  failureToMaintainClearance" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>


                      <mat-optgroup>
                        Surface Conditions
                        <mat-option *ngFor="let item of  surfaceConditionsCF" [value]="item.name">{{item.name}}</mat-option>
                      </mat-optgroup>
                      <mat-optgroup>
                        Other

                        <mat-option *ngFor="let item of  other" [value]="item.name" >{{item.name}}</mat-option>
                      </mat-optgroup>


                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-1">
                    <mat-label>
                      Registration
                    </mat-label>
                    <input matInput value="" formControlName="reg_no" (blur)="onFocusOutEvent($event, 'miscInfomration', 'reg_no')">
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>
                      Incident Edit Stage
                    </mat-label>

                    <mat-select multiple formControlName="analyst_editing_stage" (blur)="onFocusOutEvent($event, 'miscInfomration','analyst_editing_stage')">
                  
                      <mat-option *ngFor="let item of filteredIncidentEditStage" [value]="item.name" >{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>
                      Incident Id
                    </mat-label>

                    <input matInput value="" formControlName="merged_data_source_id" (blur)="onFocusOutEvent($event, 'miscInfomration', 'merged_data_source_id')">

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-2">
                    <mat-label>
                      Last Editing Analyst
                    </mat-label>

                    <input matInput value="" formControlName="modify_user" (blur)="onFocusOutEvent($event, 'miscInfomration', 'modify_user')">

                  </mat-form-field>

                  <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>
                      Keywords
                    </mat-label>

                    <input matInput value="" formControlName="keywords" (blur)="onFocusOutEvent($event, 'miscInfomration', 'keywords')">

                  </mat-form-field>
                  <mat-form-field appearance="fill" class="col-md-1">
                    <mat-label>
                      Max Rows
                    </mat-label>
                    <input matInput value="" formControlName="maxRow" (blur)="onFocusOutEvent($event, 'miscInfomration', 'maxRow')">
                  </mat-form-field>
                </div>

              </mat-expansion-panel>

            </mat-accordion>


          </div>
        </div>

        <div class="card card-savedQuery" [hidden]="reportType == 'INCIDENT_BY_STATUS'  ">

          <div class="card-body">
            <div class="row">
              <div class="col-md-9">
                <mat-form-field class="mat-full-width">
                  <mat-label>Saved Queries</mat-label>
                  <span matPrefix>&nbsp;</span>
                  <mat-select class="custom-select" #savedQuerySelect [(ngModel)]="currentSavedQueryId" (ngModelChange)="onSavedQueryChange($event)" matTooltip="Choose a saved query from list and click run to load data in grid">
                    <mat-select-trigger>
                      {{queryName}}
                    </mat-select-trigger>
                    <mat-option value=""></mat-option>
                    <ng-template [ngIf]="queries && queries?.length > 0">
                      <mat-option [value]="item.id" *ngFor="let item of queries">
                        <mat-icon *ngIf="item.ispublic" class="text-danger">public</mat-icon> <mat-icon *ngIf="!item.ispublic" class="text-success">public_off</mat-icon> <mat-icon *ngIf="(item.createdby == userName)" class="text-success"><span class="material-icons">lock_open</span></mat-icon> <mat-icon *ngIf="(item.createdby !== userName)" class="text-danger">lock</mat-icon>{{item.name}}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                  <div matSuffix class="faa-btn-right-space-5px">
                    <button class="btn btn-outline-primary btn-sm" *ngIf="queries?.length > 0" (click)="onShowSideBarQuery($event)" matTooltip="Manage saved queries like update or delete">Manage</button>
                    <button class="btn btn-outline-secondary btn-sm " *ngIf="queryName !== '' && currentSavedQueryId > 0 && isQueryOwner" (click)="onOpenManageQueryDialog($event, currentSavedQueryId, 'UPDATE')" matTooltip="Update selected query information">Update</button>
                    <button class="btn btn-outline-secondary btn-sm" *ngIf="queryName === ''  && query?.rules?.length > 0" (click)="onOpenManageQueryDialog($event, currentSavedQueryId, 'ADD')" matTooltip="Add new Saved Query">Add New</button> 
                    <button class="btn btn-outline-secondary btn-sm" *ngIf="queryName !== ''  && currentSavedQueryId > 0" (click)="onOpenManageQueryDialog($event, currentSavedQueryId,'SAVE_AS')" matTooltip="Create a copy of current query with another name">Save As...</button>
                    <button class="btn btn-outline-secondary btn-sm" *ngIf="queryName !== '' && currentSavedQueryId > 0 && isQueryOwner " (click)="onOpenManageQueryDialog($event, currentSavedQueryId, 'RENAME')" matTooltip="Rename current saved query with new name">Rename</button>
                  </div>
                </mat-form-field>

              </div>
              <div class="col-3 text-end">
                <button mat-raised-button class="btn btn-primary faa-btn-right-space-5px" (click)="onRunQuery()" matTooltip="Click run to apply current filters and data will loaded in to grid view" [disabled]="!(query && query.rules && query.rules.length > 0)">Run</button>

                <button mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px" (click)="onDownload()" matTooltip="Click to download current grid view data" [disabled]="!hasGridData">Download</button>
                <button mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px" (click)="onDownloadAll()" matTooltip="Click to download all data" *ngIf="reportType == 'AIRPORT'">Download All</button>
                <button mat-raised-button class="btn btn-outline-dark btn-s faa-btn-right-space-5px" (click)="onClearQuery()" matTooltip="Click to clear all current filters">Clear</button>
              </div>
            </div>
          </div>
        </div>


        <div class="card" *ngIf="reportType === 'AIRPORT'
             || reportType === 'INCIDENT'
             || reportType === 'INCIDENT_BY_STATUS'
             || reportType === 'NTSB'
             || reportType === 'RWS'
             || reportType === 'VPDS'
             || reportType === 'PDS'
             || reportType === 'AIDS'
             || reportType === 'ASRS'
             "
             style="margin-top:5px !important;">

          <div class="card-header primary-header">
            <div class="row text-ce">
              <div class="col-3">
                <b>Report Data</b>
              </div>
              <div class="col-9 text-end">
               
                <div class="page-size" [hidden]="!hasGridData">
                  <span class="text-end">Jump to Page: <input style="width:40px;" class="text-center" type="text" [(ngModel)]="jumpToPageNo" (blur)="onJumpToPageNumber()" /></span>
                  <span>
                    Page Size:
                    <select (change)="onPageSizeChanged(pageSize)" id="page-size" name="pageSize" [(ngModel)]="pageSize">
                      <option value="10">10</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </span>
                  <span class="text-end" *ngIf="hasGridData && ( reportType === 'INCIDENT' || reportType === 'INCIDENT_BY_STATUS') " style="margin-left:15px;">

                    <button mat-raised-button class="btn btn-primary faa-btn-right-space-5px" (click)="onCustomizeDownload()" matTooltip="Click to Customize & Download data">Customize &amp; Download</button>

                    <button mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px" (click)="onExport('EXPORT_NARRATIVE')" matTooltip="Click to view Narrative data">Export Narrative</button>
                    <button mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px" (click)="onExport('AIRCRAFT_INFORMATION')" matTooltip="Click to view Aircraft data">Export Aircraft Info</button>

                  </span>
                </div>

              </div>
            </div>
          </div>

          <div class="card-body card-body-usda-table">

            <div class="table-responsive " [hidden]="!hasGridData">
              <!--<button (click)="autoSizeAll(false)">Auto-Size All</button>-->
              <ag-grid-angular style="width: auto; height: 540px;"
                               id="ag-Grid-{{reportType}}"
                               class="ag-theme-alpine"
                               [rowData]="rowData"
                               [columnDefs]="columnDefs"
                               [gridOptions]="gridOptions"
                               [paginationPageSize]="pageSize"
                               [pagination]="true"
                               (paginationChanged)="onPaginationChanged($event)"
                               [sideBar]="true"
                               [defaultColDef]="defaultColDef"
                               (gridReady)="onGridReady($event)"
                               [autoGroupColumnDef]="autoGroupColumnDef"
                               [animateRows]="true"
                               [enableRowGroup]="true">
              </ag-grid-angular>

            </div>

            <div class="" [hidden]="hasGridData">
              <span *ngIf="!hasQueryParamter;else noDataFound">
                Please select atleast one search parameter and click run query button to load report.
              </span>
              <ng-template #noDataFound>
                <span *ngIf="message">
                  {{message}}
                </span>
              </ng-template>


            </div>
          </div>
        </div>

      </ng-template>

    </mat-card-content>
  </mat-card>
</div>



<app-sidebar-query-view [showSideBarManageQueriesView]="showSideBarManageQueriesView"
                        [selectedRow]="selectedRow"
                        [rowData]="queryRowData"
                        [reportType]="reportType"
                        [userName]="userName"
                        (onCloseSideBarManageQueriesView)="onCloseSideBarManageQueriesView($event)">

</app-sidebar-query-view>

<app-sidebar-detail-view [showSideBarDetailView]="showSideBarDetailView"
                         [showSideBarEditView]="showSideBarEditView"
                         [selectedRowIndex]="selectedRowIndex"
                         [rowDataDetailView]="rowDataDetailView"
                         [hasQueryGridData]="reportType === 'INCIDENT_BY_STATUS' ? true : hasQueryGridData "
                         [columnDefs]="columnDefs"
                         [gridApiDetail]="gridApi"
                         [type]="reportType === 'INCIDENT_BY_STATUS' ? 'INCIDENT' : reportType "
                         [totalRecords]="totalRecords"
                         [gridOptions]="gridOptions"
                         [resultReportColumns]="reportType === 'INCIDENT_BY_STATUS' ? incidentReportColumns  :  resultReportColumns"
                         (onCloseSideBarDetailView)="onCloseSideBarDetailView($event)">

</app-sidebar-detail-view>


<app-edit-view [showSideBarEditView]="showSideBarEditView"
               [selectedRowIndex]="selectedRowIndex"
               [rowDataDetailView]="rowDataDetailView"
               [hasQueryGridData]="reportType === 'INCIDENT_BY_STATUS' ? true : hasQueryGridData "
               [columnDefs]="columnDefs"
               [gridApiDetail]="gridApi"
               [resultReportColumns]="reportType === 'INCIDENT_BY_STATUS' ? incidentReportColumns  :  resultReportColumns"
               [queryColumns]="reportType === 'INCIDENT_BY_STATUS' ? incidentReportColumns  :  queryColumns" 
               [airports]="airports"
               [userName]="userName"
               [sqlQuery]="sqlQuery"
               [gridApi]="gridApi"
               [showSideBarDetailView]="showSideBarDetailView"
               [reportId]="selectedReportId"
               [selectedRow]="selectedRow"
               [type]="reportType === 'INCIDENT_BY_STATUS' ? 'INCIDENT' : reportType "
               [defaultColDef]="defaultColDef"
               [totalRecords]="totalRecords"
               (onCloseSideBarDetailView)="onCloseSideBarDetailView($event)">

</app-edit-view>

<app-sidebar-sub-report-view [showSideBarSubReportView]="showSideBarSubReportView"
                             [type]="subReportType"
                             [selectedRowIndex]="selectedRowIndex"
                             [selectedRow]="selectedRow"
                             [sqlQuery]="sqlQuery"
                             [baseZIndex]="10000"
                             [hasNewTabView]="false"
                             (onCloseSideBarSubReportView)="onCloseSideBarSubReportView($event)">

</app-sidebar-sub-report-view>

<app-column-filter-view [type]="reportType"
                        [columns]="reportType === 'INCIDENT_BY_STATUS' ? incidentReportColumnDefs : columnDefs"
                        [sqlQuery]="sqlQuery"
                        [showSideBarColumnFilterView]="showSideBarColumnFilterView" [baseZIndex]="10000"
                        (onCloseSideBarColumnFilterView)="onCloseSideBarColumnFilterView($event)">

</app-column-filter-view>
