
<mat-card class="asd-card">
  <div class="content">
    <h1>
      {{title}}
    </h1>
    <div class="card">

      <div class="card-header primary-header">
        <div class="row text-ce">
          <div class="col-sm-8">
              <b>Users</b>
          </div>
          <div class="col-sm-4 text-end">
            <button mat-raised-button color="primary" (click)="onOpenUserDialog('NEW_USER')">Add</button>
          </div>
        </div>
      </div>

      <div class="card-body card-body-usda-table">

        <div class="table-responsive ">

          <ag-grid-angular style="width: auto; height: 530px;"
                           class="ag-theme-alpine"
                           [rowData]="rowData"
                           [columnDefs]="columnDefs"
                           [gridOptions]="gridOptions"
                           [paginationAutoPageSize]="true"
                           [pagination]="true"
                           [sideBar]="true"
                           [defaultColDef]="defaultColDef"
                           (gridReady)="onGridReady($event)"
                           [autoGroupColumnDef]="autoGroupColumnDef"
                           [animateRows]="true"
                           [enableRowGroup]="true">
          </ag-grid-angular>

        </div>
      </div>
    </div>

  </div>
  </mat-card>
