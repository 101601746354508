<div class="card">
  <div class="card-header">
    <h4>Part 139 and non-Part 139 Airports with Highest Event Count</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div id="p139Chart" class="col" style="width: auto; height: 450px;"></div>
      <div id="non139Chart" class="col" style="width: auto; height: 450px;"></div>
    </div>
    <!--<div class="row">
      <div id="nonp139Chart" class="col" style="width: auto; height: 450px;"></div>
      <div id="partsChart" class="col"  style="width: auto; height: 450px;"></div>
    </div>-->
    <div style="display: none">
      <ag-grid-angular id="p139Grid" style="width: auto; height: 450px;"
                       class="ag-theme-alpine"
                       [rowData]="result139"
                       [columnDefs]="p139_columnDefs"
                       [defaultColDef]="p139_ColDef"
                       (gridReady)="onp139GridReady($event)"
                       [animateRows]="true"
                       (modelUpdated)="onp139ModelUpdated($event)">
      </ag-grid-angular>

      <ag-grid-angular id="non139Grid" style="width: auto; height: 450px;"
                       class="ag-theme-alpine"
                       [rowData]="resultnon139"
                       [columnDefs]="non139_columnDefs"
                       [defaultColDef]="non139_ColDef"
                       (gridReady)="onnon139GridReady($event)"
                       [animateRows]="true"
                       (modelUpdated)="onnon139ModelUpdated($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
