
<h1 mat-dialog-title>{{data?.action}} | Confirmation</h1>
<hr />
<div mat-dialog-content>
  <b>Are you sure want to Un-merge selected report?</b>
</div>
<hr />
<div class="col-md-12">
  <mat-form-field appearance="fill" class="col-md-12">
    <mat-label>
      Un-Merge Comment:
    </mat-label>
    <textarea matInput rows="2" [(ngModel)]="data.comment" name="comment" ></textarea>

  </mat-form-field></div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="{status: 'YES', comment: data?.comment}">Yes</button>
    <button mat-raised-button mat-dialog-close>No</button>
  </div>
