
<div class="faa-header primary-dk-color" [style.background]="isDevelopmentEnv ? 'orange !important': '' ">

  <div class="header">
    <div class="container">

      <a class="siteLogo pull-left" href="http://www.faa.gov" style="margin-bottom:5px !important;">
        <img src="assets/logoFAA.png" alt="FAA seal" width="84" height="84" />

        <span style="margin-left:5px !important;">Federal Aviation Administration</span>
      </a>

      <ul class="topNav" role="menubar" style="padding-top:15px !important">
        <li role="presentation" style="padding-top: 2px" class="d-none d-sm-block">
          <a href="http://www.faa.gov" role="menuitem">FAA Home</a>
        </li>
        <li role="presentation" style="padding-top: 2px" class="d-none d-sm-block">
          <a href="/" role="menuitem">ASD | Home</a>
        </li>
        <li class="forYou" *ngIf="!(isAuthenticated | async) " role="presentation" aria-haspopup="true" style="padding-top: 2px">

          <button class="btn btn-sm" (click)="openLoginDialog('LOGIN')" id="loginLink" style="background:#937206; color: white!important;font-weight:bold; ">Login</button>


        </li>

        <li role="presentation" class="dropdown" *ngIf="(isAuthenticated | async)  ">

          <div ngbDropdown class="d-inline-block">
            <a class="btn btn-link" id="dropdownBasic1" ngbDropdownToggle>  <b class="d-inline"><small>Welcome </small> <i class="fas fa-user-circle"></i>  {{ userName | async}} </b></a>

            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button class="dropdown-item" (click)="openLoginDialog('CHANGE_PASSWORD')">Change Password</button>
              <button class="dropdown-item" (click)="onUserDialog('PROFILE')">Manage Profile</button>
              <button class="dropdown-item" (click)="onLogout()">Log Out</button>

            </div>
          </div>

        </li>


      </ul>

    </div>
  </div>

</div>

<nav class="navbar navbar-expand-lg navbar-dark primary-color">

  <button class="navbar-toggler primary-color" type="button"
          (click)="toggleNavbar()">
    <span class="navbar-toggler-icon "></span>
  </button>


  <div class="container">
    <div class="collapse navbar-collapse"
         [ngClass]="{ 'show': navbarOpen }">

      <ul class="navbar-nav mr-auto" *ngIf="(role === 'PUBLIC' || role === '') || !(isAuthenticated  | async)">

        <li class="nav-item" routerLinkActive="active">
          <a routerLink="/home" class="nav-link">Home</a>
        </li>
      </ul>


      <ul class="navbar-nav mr-auto" *ngIf="(isAuthenticated  | async)">

        <li class="nav-item" routerLinkActive="active" *ngIf="(role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')">
          <a routerLink="/home" class="nav-link">Home</a>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="(role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')">
          <a [routerLink]="'/report'" [queryParams]="{type: 'AIRPORT'}" class="nav-link" title="Manage Users">Airport Searches</a>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="(role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')">
          <a [routerLink]="'/report'" [queryParams]="{type: 'INCIDENT'}" class="nav-link" title="Manage Users">Incident Searches</a>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="(role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')">
          <div ngbDropdown class="d-inline-block">
            <a class="btn btn-link" id="ddlReports" ngbDropdownToggle> Data Source Searches</a>
            <div ngbDropdownMenu aria-labelledby="ddlReports">
              <button class="dropdown-item" [routerLink]="'/report'" [queryParams]="{type: 'NTSB'}">NTSB Report</button>
              <button class="dropdown-item" [routerLink]="'/report'" [queryParams]="{type: 'RWS'}">RWS Report</button>
              <button class="dropdown-item" [routerLink]="'/report'" [queryParams]="{type: 'VPDS'}">VPDS Report</button>
              <button class="dropdown-item" [routerLink]="'/report'" [queryParams]="{type: 'PDS'}">PDS Report</button>
              <button class="dropdown-item" [routerLink]="'/report'" [queryParams]="{type: 'AIDS'}">AIDS Report</button>
              <button class="dropdown-item" [routerLink]="'/report'" [queryParams]="{type: 'ASRS'}">ASRS Report</button>

            </div>
          </div>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="(role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')">
          <div ngbDropdown class="d-inline-block">
            <a class="btn btn-link" id="ddlReports" ngbDropdownToggle> Incident By Status</a>
            <div ngbDropdownMenu aria-labelledby="ddlReports">
              <button class="dropdown-item" [routerLink]="'/report'" [queryParams]="{type: 'INCIDENT_BY_STATUS', status: 'REVIEW'}"> Review </button>
              <button class="dropdown-item" [routerLink]="'/report'" [queryParams]="{type: 'INCIDENT_BY_STATUS', status: 'IN_PROGRESS'}"> In Progress</button>
              <button class="dropdown-item" [routerLink]="'/report'" [queryParams]="{type: 'INCIDENT_BY_STATUS', status: 'UNREVIEWED'}"> UnReviewed</button>

            </div>
          </div>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="(role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')">
          <div ngbDropdown class="d-inline-block">
            <a class="btn btn-link" id="ddlReports" ngbDropdownToggle> Duplicates/Merge</a>
            <div ngbDropdownMenu aria-labelledby="ddlReports">
              <button class="dropdown-item" [routerLink]="'/potentialDup'" [queryParams]="{type: 'POT_DUP'}"> Potential Duplicates  </button>
              <button class="dropdown-item" [routerLink]="'/mergeReport'" [queryParams]="{type: 'MERGE_MANUAL'}"> Merge a Report  </button>
              <button class="dropdown-item" [routerLink]="'/potentialDup'" [queryParams]="{type: 'MERGED_HISTORY'}"> Merged History Report </button>
            </div>
          </div>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="(role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')">
          <div ngbDropdown class="d-inline-block">
            <a class="btn btn-link" id="ddlReports" ngbDropdownToggle> Reports</a>
            <div ngbDropdownMenu aria-labelledby="ddlReports">
              <button class="dropdown-item" [routerLink]="'/bi-report'"> Power BI Dashboard Report</button>
              <div class="dropdown-divider" title="Incident Reports">Incident Reports</div>
              <button class="dropdown-item" [routerLink]="'/ingestStatisticsReport'" [queryParams]="{type: 'STATISTICS_COUNT'}"> Ingest Statistics </button>
              <button class="dropdown-item" [routerLink]="'/analystReport'" [queryParams]="{type: 'STATISTICS_COUNT'}"> Incident Statistics Report </button>
              <button class="dropdown-item" [routerLink]="'/analystReport'" [queryParams]="{type: 'INCIDENT_HISTORY'}"> Incident History Report</button>
              <div class="dropdown-divider" title=">Analyst Deliverable Reports">Analyst Deliverable Reports</div>
              <button class="dropdown-item" [routerLink]="'/analystReport'" [queryParams]="{type: 'OCCURRENCE_EVENTS_REPORT'}"> Occurrence Types Event Report</button>
              <button class="dropdown-item" [routerLink]="'/analystReport'" [queryParams]="{type: 'OPERATION_TYPE_EVENTS_REPORT'}"> Operation Types Event Report</button>
              <button class="dropdown-item" [routerLink]="'/analystReport'" [queryParams]="{type: 'PART139_AIRPORT_REPORT'}"> Part 139 Airports Report</button>
              <button class="dropdown-item" [routerLink]="'/analystReport'" [queryParams]="{type: 'NON_STD_GEOMETRY_CF_EVENTS_REPORT'}"> Non-standard Geometry Contributing Factors Events Report</button>
              <button class="dropdown-item" [routerLink]="'/analystReport'" [queryParams]="{type: 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT'}"> Non-geometry Airport-Related Contributing Factors Events Report</button>
              <button class="dropdown-item" [routerLink]="'/analystReport'" [queryParams]="{type: 'CONTRIBUTING_FACTORS_REPORT'}"> Contributing Factors Report</button>
              <button class="dropdown-item" [routerLink]="'/highestRankedOccurrences'" [queryParams]="{type: 'CONSEQUENCES_HIGHEST_RANKED_OCCURRENCES_REPORT'}"> Consequences of Highest-Ranked Occurrences Report</button>
              <button class="dropdown-item" [routerLink]="'/analystReport'" [queryParams]="{type: 'SEVERITY_DISTRIBUTION_RUNWAY_INCURSIONS_PD_REPORT'}"> Severity Distribution of Runway Incursions – PD Report</button>
              <div ngbDropdown style="background-color:blanchedalmond; border:none;">
                <button class="btn btn-link btn-submenu" id="staticalReports" ngbDropdownToggle> Statistical Reports</button>
                <div ngbDropdownMenu aria-labelledby="staticalReports">
                  <button ngbDropdownItem [routerLink]="'/reportLoC'"> Statistical Reports</button>
                  <button ngbDropdownItem [routerLink]="'/reportPvNTotal'"> Total Events Part-139 and Non Part-139 Report</button>
                  <button ngbDropdownItem [routerLink]="'/reportConseqOccur'"> Consequences by Occurrence Type</button>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="(role === 'ADMIN')">
          <a [routerLink]="'/manageUser'" class="nav-link" title="Manage Users">Manage Users</a>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="(role === 'ADMIN')">
          <div ngbDropdown class="d-inline-block">
            <a class="btn btn-link" id="ddlReports" ngbDropdownToggle> Documentation</a>
            <div ngbDropdownMenu aria-labelledby="ddlReports">

              <a class="dropdown-item a-color-black"
                 href="/swagger/index.html" target="_blank" rel="noopener noreferrer">
                API
              </a>
              <a class="dropdown-item a-color-black" href="https://qa-asd.faa.gov/documentation/index.html" target="_blank" rel="noopener noreferrer">
                Frontend
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
