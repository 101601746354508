<ng-template  [ngIf]="showSideBarDetailView ">
  <p-sidebar [(visible)]="showSideBarDetailView" (onHide)="onHide()" [style]="{width:'80%'}" [showCloseIcon]="false" position="right" [baseZIndex]="10000" [dismissible]="false" [closeOnEscape]="false">
    <ng-template pTemplate="header">
      <div class="container">
        <div class="row row-cols-3">
          <div class="col h2">Detail View</div>
          <div class="col text-center">
            <span class="table-responsive1" [hidden]="!hasQueryGridData">
              <p-paginator [first]="first" [rows]="1" [totalRecords]="totalRecords" (onPageChange)="onPageChange($event)"
                           [showCurrentPageReport]='true' [showPageLinks]="false"></p-paginator>
            </span>
          </div>
          <div class="col text-end">
            <button mat-mini-fab color="primary" (click)="onHide()" matTooltip="Close window" aria-label="Close">
              <mat-icon>close</mat-icon>
            </button>
          </div>

        </div>
      </div>

    </ng-template>
    <div class="card">
      <div class="card-body">

        <div class="card-body card-body-usda-table">

          <div class="table-responsive " [hidden]="!hasQueryGridData">

            <div class="form detail-form">

              <form>
                <div class="form-row row" *ngIf="columnDefs?.length > 0 && showSideBarDetailView && !hasOtherDataSourceType">

                  <ng-template ngFor let-item [ngForOf]="columnDefs">

                    <mat-form-field [ngClass]="getBsClass(item.field)" *ngIf="item.headerName?.length > 0">
                      <mat-label style="font-weight: bold">{{item.headerName}}:</mat-label>
                      <input matInput value="{{selectedRow[item.field]}}" [readonly]="isReadOnly">

                    </mat-form-field>

                  </ng-template>

                </div>

                <div class="form-row row" *ngIf="resultReportColumns?.length > 0 && hasOtherDataSourceType">

                  <ng-template ngFor let-item [ngForOf]="resultReportColumns">

                    <mat-form-field [ngClass]="getBsClass(item.db_column_name)" *ngIf="item.display_name?.length > 0 && rowData && rowData[0]">
                      <mat-label style="font-weight: bold">{{item.display_name}}:</mat-label>
                      <ng-template [ngIf]="item.control == 'TEXTAREA'">
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly"></textarea>
                      </ng-template>
                      <ng-template [ngIf]="item.control !== 'TEXTAREA'">
                        <input matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly">
                      </ng-template>

                    </mat-form-field>

                  </ng-template>

                </div>

              </form>
            </div>

          </div>

          <div class="" [hidden]="hasQueryGridData">
            No data found.
          </div>
        </div>


      </div>
    </div>
  </p-sidebar>
</ng-template>
