<div *ngIf="label == 'INCIDENT'">
  <button mat-mini-fab color="primary" aria-label="button" matTooltip="Edit View"
          *ngIf="role == 'ADMIN' || role == 'REVIEWER' "
          [disabled]="disabled" (click)="btnClickedHandler($event, 'PAGE_EDIT' )" class="app-matt-button-cell">
    <mat-icon>edit</mat-icon>
  </button>&nbsp;
  <button mat-mini-fab color="primary" aria-label="button" matTooltip="Detail View" [disabled]="disabled" (click)="btnClickedHandler($event, 'PAGE_VIEW' )" class="app-matt-button-cell">
    <mat-icon>remove_red_eye</mat-icon>
  </button>&nbsp;
  <button mat-mini-fab color="secondary" aria-label="button" matTooltip="Narrative Link" [disabled]="params?.data?.narrative_link !== 'Y'" (click)="btnClickedHandler($event, 'LINK' )" class="app-matt-button-cell">
    <mat-icon>link</mat-icon>
  </button>&nbsp;

  <button mat-mini-fab color="secondary" aria-label="button" matTooltip="NTSB Link" [disabled]=" params?.data?.ntsb_icon !== 'Y'" (click)="btnClickedHandler($event, 'NTSB_LINK' )" class="app-matt-button-cell">N</button>&nbsp;
  <button mat-mini-fab color="secondary" aria-label="button" matTooltip="RWS Link" [disabled]=" params?.data?.rws_icon !== 'Y'" (click)="btnClickedHandler($event, 'RWS_LINK' )" class="app-matt-button-cell">R</button>&nbsp;
  <button mat-mini-fab color="secondary" aria-label="button" matTooltip="VPDS Link" [disabled]=" params?.data?.vpds_icon !== 'Y'" (click)="btnClickedHandler($event, 'VPDS_LINK' )" class="app-matt-button-cell">V</button>&nbsp;

  <button mat-mini-fab color="secondary" aria-label="button" matTooltip="PDS Link" [disabled]=" params?.data?.pds_icon !== 'Y'" (click)="btnClickedHandler($event, 'PDS_LINK' )" class="app-matt-button-cell">P</button>&nbsp;
  <button mat-mini-fab color="secondary" aria-label="button" matTooltip="AIDS Link" [disabled]=" params?.data?.aid_icon !== 'Y'" (click)="btnClickedHandler($event, 'AIDS_LINK' )" class="app-matt-button-cell">A</button>&nbsp;
  <button mat-mini-fab color="secondary" aria-label="button" matTooltip="ASRS Link" [disabled]=" params?.data?.asrs_icon !== 'Y'" (click)="btnClickedHandler($event, 'ASRS_LINK' )" class="app-matt-button-cell">S</button>&nbsp;

</div>
<div *ngIf="label !== 'INCIDENT'">
  <button *ngIf="label != 'layers' " mat-mini-fab color="{{color}}" aria-label="button" matTooltip="{{tooltip}}" [disabled]="disabled" (click)="btnClickedHandler($event)" class="app-matt-button-cell">
    <mat-icon>{{label}}</mat-icon>
  </button>
  <button *ngIf="label == 'layers' &&  params?.data?.airport_diagram_link === 'Y' " mat-mini-fab color="{{color}}" aria-label="button" [disabled]="disabled" matTooltip="{{tooltip}}" (click)="btnClickedHandler($event)" class="app-matt-button-cell">
    <mat-icon>{{label}}</mat-icon>
  </button>
</div>
