import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-mat-btn-cell-renderer',
  templateUrl: './mat-btn-cell-renderer.component.html',
  styleUrls: ['./mat-btn-cell-renderer.component.css']
})
export class MatBtnCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  label = 'edit';
  color = 'primary';
  tooltip = '';
  disabled: boolean = false;
  checked: boolean = false;
  role = '';
  agInit(params: any): void {
    this.params = params;
    
    this.tooltip = this.params.tooltip;
    this.role = this.params.role;
    if (this.params.label) {
      switch (this.params.label) {
        case 'INCIDENT': this.label = 'INCIDENT'; this.color = 'primary'; break;
        case 'UPDATE': this.label = 'edit'; this.color = 'primary'; break;
        case 'RENAME': this.label = 'save_alt'; this.color = 'accent'; break;
        case 'SAVE_AS': this.label = 'save'; this.color = 'secondary'; break;
        case 'DELETE': this.label = 'delete'; this.color = 'warn'; break;
        case 'PAGE_VIEW': this.label = 'remove_red_eye'; this.color = 'primary'; break;
        case 'DIAGRAM': this.label = 'layers'; this.color = 'secondary'; break;
        case 'MAP': { this.label = 'remove_red_eye'; this.color = 'primary'; this.tooltip = 'View Map Diagram' } break;
        case 'LINK': this.label = 'link'; this.color = 'secondary'; break;
        case 'MERGE': this.label = 'difference'; this.color = 'primary'; break;
        case 'UNMERGE': this.label = 'difference'; this.color = 'warn'; break;
       
         default: this.label = 'edit'; this.color = 'primary'; break;
      }
    }

    if (params.userName && params.userName !== '' && params.data.createdby)
      this.disabled = params.userName !== params.data.createdby;
    if (this.params.label == 'LINK' && this.params.data && this.params.data.narrative_link !== 'Y')
      this.disabled = true;
    if (this.params.label == 'NTSB_LINK' && this.params.data && this.params.data.ntsb_icon !== 'Y')
      this.disabled = true;
    if (this.params.label == 'RWS_LINK' && this.params.data && this.params.data.rws_icon !== 'Y')
      this.disabled = true;
    if (this.params.label == 'VPDS_LINK' && this.params.data && this.params.data.vpds_icon !== 'Y')
      this.disabled = true;
    if (this.params.label == 'PDS_LINK' && this.params.data && this.params.data.pds_icon !== 'Y')
      this.disabled = true;
    if (this.params.label == 'AIDS_LINK' && this.params.data && this.params.data.aid_icon !== 'Y')
      this.disabled = true;
    if (this.params.label == 'ASRS_LINK' && this.params.data && this.params.data.asrs_icon !== 'Y')
      this.disabled = true;
    if (this.params.label == 'UNMERGE' && this.params.data && this.params.data.unmerged !== false)
      this.disabled = true;
    this.checked = this.params.value === "On" || this.params.value === "TRUE" || this.params.value;
  }


  btnClickedHandler(params: any, actionType = '') {
    params.stopPropagation();
    if (this.label == 'INCIDENT') {
      const params = {
        params: this.params,
        type: actionType
       
      }
      this.params.clicked(params);
    }
    else
    this.params.clicked(this.params);
  }

  refresh(params: any): boolean {
    return false;
  }
}
