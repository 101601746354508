<div class="content container">

  <div class="asd-card card">
    <div class="card-body">
      <div class="container">
        <div class="card-title">
          <h1>404 Error</h1>
        </div>
        <hr>
        Page/File Not Found
      </div>
    </div>
    </div>
</div>
