
<div class="container-fluid">

  <mat-card class="asd-card">

    <mat-card-header>

      <mat-card-title>
        <h1>{{title}}</h1>
      </mat-card-title>
      <hr>
    </mat-card-header>

    <mat-card-content>
      <ng-template [ngIf]="true" class="container-fluid statistic-count">
        <div class="card card-query" *ngIf="reportType === 'OCCURRENCE_EVENTS_REPORT' || reportType === 'PART139_AIRPORT_REPORT' || reportType === 'OPERATION_TYPE_EVENTS_REPORT'
             || reportType == 'NON_STD_GEOMETRY_CF_EVENTS_REPORT' || reportType == 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT'
             || reportType == 'CONTRIBUTING_FACTORS_REPORT'
             || reportType == 'STATISTICS_COUNT' || reportType == 'SEVERITY_DISTRIBUTION_RUNWAY_INCURSIONS_PD_REPORT' ">
          <div class="card-header">
            <b>Search Parameter(s)</b>
          </div>
          <div class="card-body">
            @if (reportType === 'STATISTICS_COUNT' ){
            <div class="form-row row">
              <mat-form-field appearance="fill" class="col-md-3">
                <mat-label>Date range(To and From)</mat-label>
                <mat-date-range-input [formGroup]="dateRangeReport" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions >
                    <button mat-button matDateRangePickerCancel  (click)="onClearDateRange()">Cancel</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply (click)="loadReportData()">Apply</button>
                  </mat-date-range-picker-actions>
                  <mat-error *ngIf="dateRangeReport.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                  <mat-error *ngIf="dateRangeReport.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-date-range-picker>

              </mat-form-field>
            </div>
            }
            @else if (reportType === 'SEVERITY_DISTRIBUTION_RUNWAY_INCURSIONS_PD_REPORT' ){
          
            <div class="form-row row">
              <mat-form-field appearance="fill" class="col-md-2">
                <mat-label>Date range(To and From)</mat-label>
                <mat-date-range-input [formGroup]="dateRangeReport" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply (click)="loadReportData()">Apply</button>
                  </mat-date-range-picker-actions>
                  <mat-error *ngIf="dateRangeReport.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                  <mat-error *ngIf="dateRangeReport.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-date-range-picker>

              </mat-form-field>
            </div>
            }
            @else {
            <div class="form-row row">
              <mat-form-field appearance="fill" class="col-md-2">
                <mat-label>Date range(To and From)</mat-label>
                <mat-date-range-input [formGroup]="dateRangeReport" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions *ngIf="!hasChartView && reportType !== 'OCCURRENCE_EVENTS_REPORT'">
                    <button mat-button matDateRangePickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply (click)="loadReportData()">Apply</button>
                  </mat-date-range-picker-actions>
                  <mat-error *ngIf="dateRangeReport.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                  <mat-error *ngIf="dateRangeReport.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-date-range-picker>

              </mat-form-field>

              <ng-template [ngIf]="hasChartView || reportType !== 'PART139_AIRPORT_REPORT' || reportType === 'OCCURRENCE_EVENTS_REPORT'  ">
                <mat-form-field appearance="fill" class="col-md-2">
                  <mat-label>Location</mat-label>
                  <mat-select multiple [(ngModel)]="location_display">
                    @if (hasLoadedLookupData){
                    <mat-option *ngFor="let item of filteredLocations" [value]="item.code"
                                (focusout)="onFocusOutEvent($event, 'location', 'location_display')">{{item.name}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-md-3">
                  <mat-label>Occurrence Items</mat-label>
                  <mat-select multiple [(ngModel)]="occurrence_display">
                    @if (hasLoadedLookupData){
                    <mat-option *ngFor="let item of filteredOccurrenceItems" [value]="item.code" (focusout)="onFocusOutEvent($event, 'occurrences', 'occurrence_display', occurrence_display)">{{item.name}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-md-3">
                  <mat-label>Type Of Operations</mat-label>
                  <mat-select multiple [(ngModel)]="operation_type_display">
                    @if (hasLoadedLookupData){
                    <mat-option *ngFor="let item of filteredOperationTypes" [value]="item.code" (focusout)="onFocusOutEvent($event, 'opsFlightPhase', 'operation_type_display', operation_type_display)">{{item.name}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <div class="text-end">
                  <br />
                  <button mat-raised-button class="btn-primary" (click)="loadReportData()">Search</button>&nbsp;&nbsp;
                  <button mat-raised-button (click)="onClearInput()">Clear</button>
                </div>

              </ng-template>

            </div>
            }
          </div>
        </div>

        <div class="card card-query" *ngIf="reportType === 'INCIDENT_HISTORY' ">
          <div class="card-header">
            <b>Search Parameter(s)</b>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-7">
                <mat-form-field appearance="fill" class="col-md-7">
                  <mat-label>
                    Incident ID
                  </mat-label>
                  <input matInput [(ngModel)]="merged_data_source_id" name="merged_data_source_id" />

                </mat-form-field>

                <button mat-raised-button class="btn-primary" (click)="loadReportData()">Search</button>&nbsp;&nbsp;
                <button mat-raised-button (click)="onClearInput()">Clear</button>
              </div>

            </div>
          </div>
        </div>

        <div class="card">

          <div class="card-header primary-header">
            <div class="row text-ce">
              <div class="col-sm-6"><b>Report Data</b> </div>
              <div class="col-sm-6 text-end">
                <button mat-raised-button class="btn btn-outline-primary " (click)="onDownload()" matTooltip="Click to download current grid view data" [disabled]="!hasGridData">Download</button>
              </div>
            </div>
          </div>
          <div class="card-body card-body-usda-table">
            <div class="table-responsive " [hidden]="!hasGridData">
              <div class="row">

                <div class="" [hidden]="!hasChartView" [ngClass]="hasChartView && (reportType == 'NON_STD_GEOMETRY_CF_EVENTS_REPORT' || reportType == 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT' || reportType == 'CONTRIBUTING_FACTORS_REPORT') ? 'col-md-12' : 'col-md-7'">
                  <div id="myChart" class="myChart ag-theme-alpine my-chart" style="width: auto; height: 450px;"></div>
                </div>
                <div class="" [ngClass]="hasChartView && (reportType == 'NON_STD_GEOMETRY_CF_EVENTS_REPORT'|| reportType == 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT' || reportType == 'CONTRIBUTING_FACTORS_REPORT') ?
                                      'col-md-12' : ( hasChartView ? 'col-md-5' : 'col-md-12') ">
                  <ag-grid-angular id="myGrid" style="width: auto; height: 450px;"
                                   class="ag-theme-alpine"
                                   [rowData]="rowData"
                                   [columnDefs]="columnDefs"
                                   [gridOptions]="gridOptions"
                                   [defaultColDef]="defaultColDef"
                                   (gridReady)="onGridReady($event)"
                                   [autoGroupColumnDef]="autoGroupColumnDef"
                                   (firstDataRendered)="onFirstDataRendered($event)"
                                   (modelUpdated)="onModelUpdated($event)"
                                   [chartThemeOverrides]="chartThemeOverrides"
                                   [animateRows]="true">
                  </ag-grid-angular>
                </div>
              </div>
            </div>

            <div *ngIf="message && hasGridData == 0">
              {{message}}
            </div>
            <div *ngIf="!message && hasGridData == 0">
              Please use search input and click search button to load report.
            </div>
          </div>
        </div>

      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
