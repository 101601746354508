
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';

import { MessageService } from 'primeng/api';


import { Observable } from 'rxjs';
import { AuthorizeService } from '../../../api-authorization/authorize.service';

import { MatBtnCellRendererComponent } from '../../agGrid/mat-btn-cell-renderer/mat-btn-cell-renderer.component';
import { MatCheckboxComponent } from '../../agGrid/mat-checkbox/mat-checkbox.component';
import { MatSideToggleComponent } from '../../agGrid/mat-side-toggle/mat-side-toggle.component';
import { FAALocalStorageService } from '../../services/localStorage.service';
import { UserDialog } from './user-dialog.component';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  title = 'Manage Users';
  alertType = 'success';
  message = '';
  type = 'NEW_USER';
  result = {};
  selectUser = {};

  rowData: any[] | null;

  sideBar;
  gridApi;
  gridColumnApi;
  defaultColDef;
  columnDefs;
  autoGroupColumnDef;


  constructor(public authorizeService: AuthorizeService, public dialog: MatDialog, private localStorageService: FAALocalStorageService,
    public router: Router, private spinnerService: SpinnerService,
    private messageService: MessageService) {


  }

  ngOnInit(): void {
    let role = this.authorizeService.getUserRole();
    if (role && role !== 'ADMIN')
      this.router.navigate(['/home']);

    this.loadUsers();
    this.columnDefs = [
      {
        field: 'email', headerName: ' ', width: 70,
        pinned: true, sortable: false, filter: false,
        cellRenderer: MatBtnCellRendererComponent,
        cellRendererParams: {
          clicked: this.editUserDialog.bind(this)
        },
      },
      {
        field: 'active', headerName: 'Active', pinned: true, sortable: true, filter: true, width: 100,
        cellRenderer: MatSideToggleComponent,
        cellRendererParams: {
          clicked: this.onUpdateUserStatus.bind(this)
        },
      },
      {
        field: 'role', sortable: true, filter: true, width: 100, pinned: true,
        cellRenderer:
          function (params) {

            return params.value ?
              (params.value == 'PUBLIC' ? '<span class="badge-fontSize col-md-12 badge text-bg-info">' + params.value + '</span>' :
                (params.value == 'ADMIN' ? '<span class="col-md-12 badge text-bg-success badge-fontSize">' + params.value + '</span>' :
                  (params.value == 'REVIEWER' ? '<span class="h4 col-md-12 badge text-bg-primary badge-fontSize">' + params.value + '</span>' :
                    (params.value == 'VIEWER' ? '<span class="h4 col-md-12 badge text-bg-warning">' + params.value + '</span>' :
                      (params.value == '' ? '<span class="h4 col-md-12 badge text-bg-danger">' + params.value + '</span>' : params.value))
                  )))
              : params.value;

          }
      },
      { field: 'firstname', pinned: true, sortable: true, filter: true, width: 200, },
      { field: 'lastname', sortable: true, filter: true, width: 150, },
      { field: 'email', sortable: true, filter: true, width: 250, },
      { field: 'phonenumber', headerName: 'Phone', sortable: true, filter: true, width: 120 }


    ];
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      enableRowGroup: true

    };
    this.sideBar = "columns";
    this.autoGroupColumnDef = { minWidth: 200 };
  }
  gridOptions = {
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    rowClassRules: {

      "text-danger": function (params) {
        if (params && params.data)
          return (params.data.active == 0 || params.data.active == false || params.data.active == 'FALSE' || params.data.active == 'Off');
      }
    }
  }
  editUserDialog(params: any) {

    this.selectUser = params.data;
    this.onOpenUserDialog('EDIT_USER');
  }
  onGridReady(params) {
    this.gridApi = params.api;
  
    this.gridApi.closeToolPanel();
    this.loadUsers();
  }
  loadUsers() {

    this.rowData = null;
    this.result = {};
    this.spinnerService.show();
    this.authorizeService.getUsers()
      .subscribe(result => {
        this.spinnerService.hide();
        let _result: any = {};
        _result = result;
        this.result = _result;

        if (_result.success) {
          this.alertType = 'success';
          this.rowData = _result.result;
         
        }
        else {

          this.alertType = 'danger';
          let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
          this.message = 'Error while loading data. ' + _errorInfo;
          this.showStatusMessage(this.message, 'Get Users', 'error');
        }

      },
        err => {

          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while loading data. ' + _errorInfo;
          this.showStatusMessage(this.message, 'Get Users', 'error');
          this.spinnerService.hide();
        });

  }

  onOpenUserDialog(type): void {
    this.type = type;
    const dialogRef = this.dialog.open(UserDialog, {
      width: '400px',
      data: { type: this.type, user: this.selectUser }

    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadUsers();

    });
  }

  onUpdateUserStatus(params: any) {
    console.log(params.data.active);
    let status = params.data.active == 1 || params.data.active == true || params.data.active == 'On' || params.data.active == 'TRUE' ? 'Active' : 'Inactive';
    let statusId = status === 'Active' ? 1 : 0;
    //params.node.setDataValue(this.params.colDef, this.checked ? "On" : "Off");
    this.message = '';
    let vm = {
      userName: params.data.username,
      statusID: statusId,
      updatedBy: this.authorizeService.getUserName()

    };
    this.spinnerService.show();
    this.authorizeService.updateUserStatus(vm)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success)
            this.showStatusMessage(params.data.username + ' is successfully marked as ' + status, 'Update User', statusId == 1 ? 'success' : 'warn');
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Unable to update user status, Please try it again! ' + _errorInfo;
            this.showStatusMessage(this.message, 'Update User', 'error');
          }

        },

        error: (err: any) => {
          this.spinnerService.hide();
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          console.log(_err);
          let _errorInfo = '';

          this.message = 'Unable to update user status, Please try it again! ' + _errorInfo;
          this.showStatusMessage(this.message, 'Update User', 'error');
        },
        complete: () => { }
      }
      );


  }

  getSelectedRows(): void {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    const selectedDataStringPresentation = selectedData.map(node => `${node.make} ${node.model}`).join(', ');

    // alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }
  showStatusMessage(message: any, action: any, severity: any = '') {
    severity == '' ? 'success' : 'error';

    this.messageService.add({ severity: severity, summary: action, detail: message });
  }

}
