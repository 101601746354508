import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
//import { Field, FieldMap } from 'angular2-query-builder';

import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Field, FieldMap } from '../../../model/shared';
import { IncidentService } from '../../../services/incident.service';
import { FAALocalStorageService } from '../../../services/localStorage.service';
import { ReportService } from '../../../services/report.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'app-incident-view',
  templateUrl: './incident-view.component.html',
  styleUrls: ['./incident-view.component.css']
})
export class IncidentViewComponent implements OnInit {
  @Input() showIncidentView = false;
  @Input() selectedRow;
  @Input() selectedRowLength: number = 0;
  @Input() reportId = '';
  @Input() queryColumns;


  @ViewChild(MatAccordion) accordion: MatAccordion;
  incidentReportForm: UntypedFormGroup;
  message = '';
  alertType = 'success';

  isReadOnly = false;
  runways: any = {};
  filteredRunways: Observable<any[]>;
  hasLoadedInicidentSearchLookupDataForSideBar = false;
  timeOfDay: any = {};
  filteredTimeOfDay: any = {};
  localTimes: any = {};
  filteredLocalTimes: any = {};


  // airports: any = {};
  filteredAirports: any = {};
  hasLoadedAirports = false;

  regions: any = {};
  filteredRegions: any = {};
  hasLoadedRegions = false;

  airportUse: any = {};
  filteredAirportUse: any = {};
  airportOwnership: any = {};
  filteredAirportOwnership: any = {};

  gaAssetRoles: any = {};
  filteredGaAssetRoles: any = {};

  _14CFRPart139: any = {};
  filtered_14CFRPart139: any = {};
  toweredAirport: any = {};
  filteredToweredAirport: any = {};
  cORE30: any = {};
  filteredCORE30: any = {};
  oEP35: any = {};
  filteredOEP35: any = {};

  nPIASHubSize: any = {};
  filteredNPIASHubSize: any = {};
  nPIASServiceLevel: any = {};
  filteredNPIASServiceLevel: any = {};


  locations: any = {};
  filteredLocations: any = {};
  weathers: any = {};
  filteredWeathers: any = {};

  precipitations: any = {};
  filteredPrecipitations: any = {};
  surfaceConditions: any = {};
  filteredSurfaceConditions: any = {};


  accidentOrIncident: any = {};
  filteredAccidentOrIncident: any = {};
  hotSpots: any = {};
  filteredHotSpots: any = {};
  occurrenceItems: any = {};
  filteredOccurrenceItems: any = {};
  operationTypes: any = {};
  filteredOperationTypes: any = {};

  flightPhases: any = {};
  filteredFlightPhases: any = {};

  specificInjuries: any = {};
  filteredSpecificInjuries: any = {};

  yesOptionOnly: any = {};
  //anyInjuries = ['YES'];
  //anyAircraftDamages = ['YES'];
  //damageToProperty = ['YES'];
  //unspecifiedTypeAircraftDamage = ['YES'];
  nTSB_AIDSDamageToAircraft: any = {};
  filteredNTSB_AIDSDamageToAircraft: any = {};

  dataSources: any = {};
  filteredDataSources: any = {};
  runwayIncursionCategory: any = {};
  filteredRunwayIncursionCategory: any = {};
  nonStandardGeometry: any = {};
  filteredNonStandardGeometry: any = {};
  construction: any = {};
  signage: any = {};
  marking: any = {};
  lighting: any = {};

  proceduresOrOperations: any = {};
  communication: any = {};
  obstructionsNearRunway: any = {};
  mechanicalFailure: any = {};

  lossOfAircraftControl: any = {};
  weather: any = {};
  lackOfOverrunAre: any = {};
  failureToMaintainClearance: any = {};
  surfaceConditionsCF: any = {};
  other: any = {};

  incidentEditStage: any = {};
  filteredIncidentEditStage: any = {};
  expanded = true;
  currentTime = new Date().getTime();

  hasReset = false;
  hasRunwayInLocation = false;
  contributingFactors = {};
  incidentSearchQuery = {
    dateTime: [], airport: [],
    occurrences: [], location: [],
    outcome: [], opsFlightPhase: [],
    miscInfomration: [],
    total: 0

  };
  first: number = 0;
  subReportType = '';
  result: any = {};
  id = '';
  showSideBarSubReportView = false;
  hasSeparateTabForNarrative = false;
  analyst_editing_stage = '';
  editableColumns = [];
  muliSelectColumn = [];

  constructor(private http: HttpClient, private fb: UntypedFormBuilder, private messageService: MessageService, public incidentLookupService: IncidentService,
    private spinnerService: SpinnerService, private localStorageService: FAALocalStorageService, private reportService: ReportService, private el: ElementRef) { }

  ngOnInit(): void {
    this.setAppDataValues();
  }
  setAppDataValues(){
    this.http.get('../../assets/appData.json?v=' + this.currentTime)
      .subscribe({
        next: (result) => {

          let _result: any = {};
          _result = result;
          if (_result.hasSeparateTabForNarrative)
            this.hasSeparateTabForNarrative = _result.hasSeparateTabForNarrative;
          if (_result.staticData.editableColumns)
            this.editableColumns = _result.staticData.editableColumns;
          if (_result.staticData.muliSelectColumn)
            this.muliSelectColumn = _result.staticData.muliSelectColumn;
          if (_result.staticData.timeOfDay) {
            this.timeOfDay = _result.staticData.timeOfDay;
            this.filteredTimeOfDay = _result.staticData.timeOfDay;
          }
          if (_result.staticData.localTimes) {
            this.localTimes = _result.staticData.localTimes;
            this.filteredLocalTimes = _result.staticData.localTimes;
          }
          if (_result.staticData.airportUse) {
            this.airportUse = _result.staticData.airportUse;
            this.filteredAirportUse = _result.staticData.airportUse;
          }
          if (_result.staticData.gaAssetRoles) {
            this.gaAssetRoles = _result.staticData.gaAssetRoles;
            this.filteredGaAssetRoles = _result.staticData.gaAssetRoles;
          }

          if (_result.staticData.airportOwnership) {
            this.airportOwnership = _result.staticData.airportOwnership;
            this.filteredAirportOwnership = _result.staticData.airportOwnership;
          }

          if (_result.staticData._14CFRPart139) {
            this._14CFRPart139 = _result.staticData._14CFRPart139;
            this.filtered_14CFRPart139 = _result.staticData._14CFRPart139;
          }

          if (_result.staticData.toweredAirport) {
            this.toweredAirport = _result.staticData.toweredAirport;
            this.filteredToweredAirport = _result.staticData.toweredAirport;
          }

          if (_result.staticData.cORE30) {
            this.cORE30 = _result.staticData.cORE30;
            this.filteredCORE30 = _result.staticData.cORE30;
          }
          if (_result.staticData.oEP35) {
            this.oEP35 = _result.staticData.oEP35;
            this.filteredOEP35 = _result.staticData.oEP35;
          }
          if (_result.staticData.nPIASHubSize) {
            this.nPIASHubSize = _result.staticData.nPIASHubSize;
            this.filteredNPIASHubSize = _result.staticData.nPIASHubSize;
          }
          if (_result.staticData.nPIASServiceLevel) {
            this.nPIASServiceLevel = _result.staticData.nPIASServiceLevel;
            this.filteredNPIASServiceLevel = _result.staticData.nPIASServiceLevel;
          }
          if (_result.staticData.weathers) {
            this.weathers = _result.staticData.weathers;
            this.filteredWeathers = _result.staticData.weathers;
          }
          if (_result.staticData.accidentOrIncident) {
            this.accidentOrIncident = _result.staticData.accidentOrIncident;
            this.filteredAccidentOrIncident = _result.staticData.accidentOrIncident;
          }
          if (_result.staticData.hotSpots) {
            this.hotSpots = _result.staticData.hotSpots;
            this.filteredHotSpots = _result.staticData.hotSpots;
          }

          if (_result.staticData.nTSB_AIDSDamageToAircraft) {
            this.nTSB_AIDSDamageToAircraft = _result.staticData.nTSB_AIDSDamageToAircraft;
            this.filteredNTSB_AIDSDamageToAircraft = _result.staticData.nTSB_AIDSDamageToAircraft;
          }

          if (_result.staticData.dataSources) {
            this.dataSources = _result.staticData.dataSources;
            this.filteredDataSources = _result.staticData.dataSources;
          }

          if (_result.staticData.nonStandardGeometry) {
            this.nonStandardGeometry = _result.staticData.nonStandardGeometry;
            this.filteredNonStandardGeometry = _result.staticData.nonStandardGeometry;
          }
          if (_result.staticData.runwayIncursionCategory) {
            this.runwayIncursionCategory = _result.staticData.runwayIncursionCategory;
            this.filteredRunwayIncursionCategory = _result.staticData.runwayIncursionCategory;
          }
          if (_result.staticData.yesOptionOnly) {
            this.yesOptionOnly = _result.staticData.yesOptionOnly;
          }

          if (_result.staticData.construction) {
            this.construction = _result.staticData.construction;
          }
          if (_result.staticData.signage) {
            this.signage = _result.staticData.signage;
          }
          if (_result.staticData.marking) {
            this.marking = _result.staticData.marking;
          }

          if (_result.staticData.lighting) {
            this.lighting = _result.staticData.lighting;
          }
          if (_result.staticData.proceduresOrOperations) {
            this.proceduresOrOperations = _result.staticData.proceduresOrOperations;
          }

          if (_result.staticData.communication) {
            this.communication = _result.staticData.communication;
          }
          if (_result.staticData.obstructionsNearRunway) {
            this.obstructionsNearRunway = _result.staticData.obstructionsNearRunway;
          }
          if (_result.staticData.mechanicalFailure) {
            this.mechanicalFailure = _result.staticData.mechanicalFailure;
          }
          if (_result.staticData.lossOfAircraftControl) {
            this.lossOfAircraftControl = _result.staticData.lossOfAircraftControl;
          }

          if (_result.staticData.weather) {
            this.weather = _result.staticData.weather;
          }
          if (_result.staticData.lackOfOverrunAre) {
            this.lackOfOverrunAre = _result.staticData.lackOfOverrunAre;
          }
          if (_result.staticData.failureToMaintainClearance) {
            this.failureToMaintainClearance = _result.staticData.failureToMaintainClearance;
          }
          if (_result.staticData.surfaceConditionsCF) {
            this.surfaceConditionsCF = _result.staticData.surfaceConditionsCF;
          }
          if (_result.staticData.other) {
            this.other = _result.staticData.other;
          }

          if (_result.staticData.incidentEditStage) {
            this.incidentEditStage = _result.staticData.incidentEditStage;
            this.filteredIncidentEditStage = _result.staticData.incidentEditStage;
          }
          if (_result.staticData.locations) {
            this.locations = _result.staticData.locations;
            this.filteredLocations = _result.staticData.locations;
          }
          if (_result.staticData.flightPhases) {
            this.flightPhases = _result.staticData.flightPhases;
            this.filteredFlightPhases = _result.staticData.flightPhases;
          }
          if (_result.staticData.specificInjuries) {
            this.specificInjuries = _result.staticData.specificInjuries;
            this.filteredSpecificInjuries = _result.staticData.specificInjuries;
          }
          if (_result.staticData.operationTypes) {
            this.operationTypes = _result.staticData.operationTypes;
            this.filteredOperationTypes = _result.staticData.operationTypes;
          }
          if (_result.staticData.occurrenceItems) {
            this.occurrenceItems = _result.staticData.occurrenceItems;
            this.filteredOccurrenceItems = _result.staticData.occurrenceItems;
          }

          if (_result.staticData.precipitations) {
            this.precipitations = _result.staticData.precipitations;
            this.filteredPrecipitations = _result.staticData.precipitations;
          }
          if (_result.staticData.surfaceConditions) {
            this.surfaceConditions = _result.staticData.surfaceConditions;
            this.filteredSurfaceConditions = _result.staticData.surfaceConditions;
          }
          if (_result.staticData.surfaceConditions)
            this.contributingFactors = _result.staticData.contributingFactors;

          this.hasLoadedInicidentSearchLookupDataForSideBar = true;
          this.getReportColumns();


        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          console.log(err);
          this.onInit();
        },
        complete: () => { }
      }
      );

}
  displayFn(result: any): string {
    return result && result.runway ? result.runway : '';
  }
  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.runways.filter(option => option.runway.toLowerCase().includes(filterValue));
  }
  onInit() {
    this.result = {};
    this.id = '';
    this.message = '';
    this.subReportType = '';
    this.showSideBarSubReportView = false;
    this.hasRunwayInLocation = true;

    this.hasReset = false;
    this.expanded = true;

    if (this.showIncidentView) {

      this.selectedRowLength = 0;
      if (this.selectedRow) {
        this.selectedRowLength = Object.entries(this.selectedRow).length;
        this.showSideBarSubReportView = true;
        this.setSearchQueryParameterColumns();
        this.loadRunways();
      }

    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.onInit();
  }
  onReset() {
    this.hasReset = true;
    this.onInit();
  }
  getReportColumns() {

    let reportType = 'INCIDENT';

    let _last_update_localStorage = this.localStorageService.get('asd-lst-update-day') || '';
    let keyid = 'asd-reportColumns-' + reportType;
    let _item = this.localStorageService.get(keyid) || [];

    if (Object.keys(_item).length > 0 && _last_update_localStorage === new Date().getDate()) {
      this.queryColumns = [];
      this.queryColumns = _item;
      this.onInit();
    }
    else {

      this.spinnerService.show();
      this.reportService.getReportColumns(reportType)
        .subscribe({
          next: (result) => {
            this.spinnerService.hide();
            let _result: any = {};
            _result = result;

            if (_result.success) {
              this.queryColumns = [];
              this.queryColumns = _result.result;
              this.onInit();
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
              this.message = 'Error while loading data. ' + _errorInfo;

              this.showStatusMessage(this.message, 'Get Report Columns', 'error');
            }

          },

          error: (err: any) => {
            
            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            this.message = 'Error while loading data. ' + _err.errorMessage;
            this.showStatusMessage(this.message, 'Get Report Columns', 'error');
            this.spinnerService.hide();
          },
          complete: () => { }
        }
        );

    }
  }
  setSearchQueryParameterColumns() {
    //console.log('setSearchQueryParameterColumns');
    let objList: Field[] = [];

    const group: any = {};
    group['start'] = (new UntypedFormControl());
    group['end'] = (new UntypedFormControl());
    group['__incident_runway'] = (new UntypedFormControl());

    Object.entries(this.muliSelectColumn).forEach(([key, val]) => {

      group[val] = (new UntypedFormControl());
    });
    if (Object.keys(this.queryColumns).length > 0) {
      Object.entries(this.queryColumns).forEach(
        ([key, item]) => {

          let x = {
            name: item['db_column_name'],
            type: item['data_type'] === 'DATE' ? 'date' : (item['data_type'] === 'NUMBER' ? 'number' : 'string'),
            operators:
              item['data_type'] === 'DATE' ? ['=', '!=', '>=', '<=', '>', '<'] : (item['data_type'] === 'NUMBER' ? ['=', '!=', '>=', '<=', '>', '<'] : (item['control'] === 'SELECT' ? ['=', '!=', 'in'] : ['=', '!=', 'like'])),
            filter: true,
            sortable: true
          }
          if (objList.indexOf(x) === -1) {
            objList.push(x);
            group[item['db_column_name']] = (new UntypedFormControl());

          }
        }
      );
    }
    const fieldMap: FieldMap = arrayToObject(objList)

    //set incident formgroup
    this.incidentReportForm = this.fb.group(group);

    //set form values
    this.setFormData();
  }
  setFormData() {

    this.analyst_editing_stage = '';
    if (this.selectedRow && Object.entries(this.selectedRow).length > 0) {
      this.isReadOnly = false;
      this.incidentReportForm.enable();
      if (this.selectedRow['analyst_editing_stage'])
        this.analyst_editing_stage = this.selectedRow['analyst_editing_stage'];

      Object.entries(this.queryColumns).forEach(
        ([key, item]) => {

          let name = item['db_column_name'];

          let control = item['control'];

          if (name && this.incidentReportForm && this.incidentReportForm.controls[name]) {
            this.incidentReportForm.controls[name]?.enable();

            let val = this.selectedRow[name];

            if (control == 'DATE') {
              if (val)
                this.incidentReportForm.controls[name]?.setValue(val.substring(0, 10));
            }

            else {

              if (val) {
                if (name == 'incident_runway') {
                  let options = { runway: val, id: this.selectedRow['runway_id'] }

                  this.incidentReportForm.controls['__incident_runway']?.setValue(options);
                }
                if (name == 'hot_spot') {
                  val = val.toLowerCase();
                  const valInitCap = val.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                  this.incidentReportForm.controls[name]?.setValue(valInitCap);
                }
                else
                  this.incidentReportForm.controls[name]?.setValue(val);
              }
              else {

                if (name == 'hot_spot')
                  this.incidentReportForm.controls[name]?.setValue('Insufficient Information');
                else
                  this.incidentReportForm.controls[name]?.setValue('');
              }
              let mSelectColumn = '_' + name;

              if (this.muliSelectColumn.indexOf(mSelectColumn) > -1) {

                var _val = String(val).split(",").map(function (item) {
                  return (name == 'occurrence_display' || name == '_occurrence_display') ? item.trim().replace(' - ', '-') : item.trim();
                }).filter((item: any) => item !== 'null');

                this.incidentReportForm.controls[mSelectColumn]?.setValue('');
                if (_val.length == 0)
                  this.incidentReportForm.controls[mSelectColumn]?.setValue('');
                else if (_val.length > 0) {

                  this.incidentReportForm.controls[mSelectColumn]?.setValue(_val);
                }
                else
                  this.incidentReportForm.controls[mSelectColumn]?.setValue(_val);

                //apply rule by calling onFocusOutEvent
                if (name == 'location_display' || name == 'occurrence_display' && this.incidentReportForm.controls[mSelectColumn]) {
                  {
                    setTimeout(() => {
                      this.onFocusOutEvent(null, mSelectColumn, mSelectColumn, 'INITIAL');
                    }, 0)

                  }
                }

              }

            }

          }
        }
      );

      setTimeout(() => { this.setEditableColumns(); }, 10);

    }


  }
  setEditableColumns() {
    let hasDetail = true;
    this.incidentReportForm.disable();

    //If not detail view then set make editable
    if (!hasDetail) {
      Object.entries(this.muliSelectColumn).forEach(([key, val]) => {
        if (this.editableColumns.indexOf(val) > -1)
          this.incidentReportForm.controls[val]?.enable();
      });
      Object.entries(this.editableColumns).forEach(([key, val]) => {

        if (this.editableColumns.indexOf(val) > -1)
          this.incidentReportForm.controls[val]?.enable();
      });
      //checkbox readonly
      this.isReadOnly = true;
    }
  }
  onSelectChangeEvent(event: any, type: any = '', control: any = '', key: any = '') {
    //show or hide related inputbox 
    if (control !== '') {
      let val = event?.value?.toString();

      // console.log(`onSelectChangeEvent => ${control}:${(val.substr(1, event.value.indexOf("-")))}  ${this.incidentReportForm.controls[control].value} `);
      if (control == '_cf_display') {

        let cf_nonstnd_geo = '';
        var _val = String(val).split(",").map(function (item) {
          return item.trim();
        });

        this.incidentReportForm.controls['cf_nonstnd_geo']?.setValue('');
        if (_val.length > 0) {
          // console.log(`multi select  =>  ${name}: ${_val}`);
          let strArray = [];
          Object.entries(_val).forEach(([key, val]) => {
            if (val.trim().indexOf("-") > -1) {
              // console.log(val.trim().indexOf("-"));
              strArray.push(val.trim());
            }
          });

          this.incidentReportForm.controls['cf_nonstnd_geo']?.setValue(strArray.toString());
        }
      }

      if (this.muliSelectColumn.indexOf(control) > -1) {

        // this will set display values based on checked boxes, old way was calling - getUpdatedSummaryValues 10/17/2022 PP
        let _summaryColumn = control.replace("_", "");
        this.incidentReportForm.controls[control]?.setValue(this.incidentReportForm.controls[control].value);
        this.incidentReportForm.controls[_summaryColumn]?.setValue(this.incidentReportForm.controls[control].value);
        _val = this.incidentReportForm.controls[control].value;
        if (control == '_location_display') {
          if (_val.indexOf('Runway') > -1) {

            this.incidentReportForm.controls['runway_length']?.enable();
            this.incidentReportForm.controls['runway_width']?.enable();
          }
          else {
            this.incidentReportForm.controls['runway_length']?.setValue('');
            this.incidentReportForm.controls['runway_width']?.setValue('');
            this.incidentReportForm.controls['runway_length']?.disable();
            this.incidentReportForm.controls['runway_width']?.disable();
          }
        }
        if (control == '_occurrence_display') {
          if (_val.indexOf('Runway Excursion - Veer Off') > -1 || _val.indexOf('Runway Excursion-Veer Off') > -1)
            this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.enable();
          else {
            this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.setValue('');
            this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.disable();
          }
          if (_val.indexOf('Runway Excursion - Overrun') > -1 || _val.indexOf('Runway Excursion-Overrun') > -1)
            this.incidentReportForm.controls['runway_excursions_overrun_distance']?.enable();
          else {
            this.incidentReportForm.controls['runway_excursions_overrun_distance']?.setValue('');
            this.incidentReportForm.controls['runway_excursions_overrun_distance']?.disable();
          }
          if (_val.indexOf('Taxiway Excursion - Veer Off') > -1 || _val.indexOf('Taxiway Excursion-Veer Off') > -1)
            this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.enable();

          else {
            this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.setValue('');
            this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.disable();
          }
        }
        return;
      }
      // this.onFocusOutEvent(event, type, control, key);

    }
  }
  onFocusOutEvent(event: any, type: any = '', control: any = '', key: any = '') {
    if (control !== '') {

      //console.log(event?.checked);
      //console.log(event);

      setTimeout(() => {
        // console.log(`onFocusOutEvent => ${control}: ${this.incidentReportForm.controls[control].value}`);
        //set summary field value

        if (this.muliSelectColumn.indexOf(control) > -1) {

          let _val = key !== '' ? this.getUpdatedSummaryValues(control, key, event?.checked) : this.incidentReportForm.controls[control].value;

          _val = _val.toString().replace("/^\/", "");

          let _summaryColumn = control.replace("_", "");
          this.incidentReportForm.controls[control]?.enable();
          this.incidentReportForm.controls[_summaryColumn]?.enable();
          this.incidentReportForm.controls[_summaryColumn]?.setValue(_val);

          if (control == '_location_display') {
            if (_val.indexOf('Runway') > -1) {

              this.incidentReportForm.controls['runway_length']?.enable();
              this.incidentReportForm.controls['runway_width']?.enable();
            }
            else {
              this.incidentReportForm.controls['runway_length']?.setValue('');
              this.incidentReportForm.controls['runway_width']?.setValue('');
              this.incidentReportForm.controls['runway_length']?.disable();
              this.incidentReportForm.controls['runway_width']?.disable();
            }
          }
          if (control == '_occurrence_display') {
            if (_val.indexOf('Runway Excursion - Veer Off') > -1 || _val.indexOf('Runway Excursion-Veer Off') > -1)
              this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.enable();
            else {
              this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.setValue('');
              this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.disable();
            }
            if (_val.indexOf('Runway Excursion - Overrun') > -1 || _val.indexOf('Runway Excursion-Overrun') > -1)
              this.incidentReportForm.controls['runway_excursions_overrun_distance']?.enable();
            else {
              this.incidentReportForm.controls['runway_excursions_overrun_distance']?.setValue('');
              this.incidentReportForm.controls['runway_excursions_overrun_distance']?.disable();
            }
            if (_val.indexOf('Taxiway Excursion - Veer Off') > -1 || _val.indexOf('Taxiway Excursion-Veer Off') > -1)
              this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.enable();

            else {
              this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.setValue('');
              this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.disable();
            }
          }
          this.incidentReportForm.controls[control]?.disable();
          this.incidentReportForm.controls[_summaryColumn]?.disable();
          //if (!this.showSideBarDetailView && this.editableColumns.indexOf(control) > -1)
          //  this.incidentReportForm.controls[control]?.enable();
          //if (!this.showSideBarDetailView && this.editableColumns.indexOf(_summaryColumn) > -1)
          //  this.incidentReportForm.controls[_summaryColumn]?.enable();

        }

      }, 1)

    }
    //  console.log(`onFocusOutEvent => ${event}: ${type}  ${event.target.value} ${event.target.getAttribute('formControlName')}`);
    if (event?.target?.value)
      return;


  }
  getUpdatedSummaryValues(control = '', key = '', checked = false) {
    let _summaryColumn = control.replace("_", "");
    let val = this.incidentReportForm.controls[_summaryColumn].value;

    let strArray = [];
    let i = 0;

    var _val = String(val).split(",").map(function (item) {
      if (item.trim() !== '')
        strArray.push((control == '_occurrence_display' || control == 'occurrence_display') ? item.trim().replace(' - ', '-') : item.trim());
    });
    if (strArray.indexOf(key) == -1 && checked && key !== 'INITIAL')
      strArray.push(key);
    if (strArray.indexOf(key) !== -1 && !checked) {
      var result = arrayRemove(strArray, key);
      return result;
    }
    return strArray;
  }

  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity == '' ? 'success' : 'error';

    this.messageService.add({ severity: severity, summary: action, detail: message });
  }

  loadRunways() {
    let loc_id = this.incidentReportForm.controls['airport_code']?.value;
    if (loc_id && loc_id != '') {
      this.runways = {};
      this.result = {};

      this.incidentLookupService.getRunwayByLocID(loc_id)
        .subscribe({
          next: (result) => {

            let _result: any = {};
            _result = result;
            this.result = _result;

            if (_result.success && _result.total > 0) {
              this.alertType = 'success';
              this.runways = _result.result;
              this.filteredRunways = _result.result;
              // this.message = _result.total > 0 ? '' : 'No data found.';
            }
            else if (_result.success && _result.total == 0) {
              this.alertType = 'success';
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result.errorMessage;
              this.message = 'Error while loading runways. ' + _result.errorMessage;
              // this.openSnackBar(this.message, 'DISMISS');
              this.showStatusMessage(this.message, 'Load Runways', 'error');
            }
          },

          error: (err: any) => {
            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            this.message = 'Error while loading data. ' + _err.errorMessage;
            this.showStatusMessage(this.message, 'Get Airport Diagram List', 'error');
          },
          complete: () => { }
        }
        );
    }

  }
  onRunwayChange($event: any) {

    this.incidentReportForm.controls['runway_id'].setValue('0');
    if ($event?.option?.value) {
      if ($event?.option?.value?.id)
        this.incidentReportForm.controls['runway_id'].setValue($event?.option?.value?.id);
      if ($event?.option?.value?.length)
        this.incidentReportForm.controls['runway_length'].setValue($event?.option?.value?.length);
      if ($event?.option?.value?.width)
        this.incidentReportForm.controls['runway_width'].setValue($event?.option?.value?.width);
    }
  }
  getBsClass(field: string) {
    let defaultBsClass = 3;

    return 'col-md-' + defaultBsClass;
  }


}

function arrayRemove(arr, value) {

  return arr.filter(function (ele) {
    return ele != value;
  });
}
const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.name] = item
    return obj
  }, {})
