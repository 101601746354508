
<div class="container-fluid">

  <mat-card class="asd-card">

    <mat-card-header>

      <mat-card-title>
        <h1>Power BI Dashboard</h1>
      </mat-card-title>
      <hr>
    </mat-card-header>

    <mat-card-content>
      @if (biReportLink){
      <div id="r2">
        The Power BI report has opened in a new tab. <button class="btn btn-primary" (click)="onOpenReportInNewWindow()">Click here to Open</button>
        <!--<small>* Please double-check your browser popup settings</small>-->
      </div>
      }@else{
      <div id="r2">
        No report is found.
      </div>
      }
    </mat-card-content>
  </mat-card>
</div>
