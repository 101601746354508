
<mat-card>
  <mat-card-content>
    <div class="card1">
      <div class="card-body1">


        <ng-template [ngIf]="data?.action !== 'DELETE'">
          <h1>{{action}} Query</h1>
          <hr />
          <form [formGroup]="queryForm" class="full-width-form">

            <ng-template [ngIf]="data?.action === 'RENAME' || data?.action === 'SAVE_AS'">
              <mat-label>Current Name: </mat-label>
              <mat-label class="font-weight-bold">{{queryForm.get('currentName').value}}</mat-label>

            </ng-template>
            <mat-form-field class="full-width-field">
              <mat-label *ngIf="data?.action === 'RENAME'">New Name</mat-label>
              <mat-label *ngIf="data?.action !== 'RENAME'">Name</mat-label>
              <input matInput formControlName="name" type="text">
            </mat-form-field>
            <mat-error *ngIf="queryForm.get('name').hasError('required')">
              Query Name is <strong>required</strong>
            </mat-error>

            <mat-error *ngIf="data?.action === 'RENAME'  || data?.action === 'SAVE_AS' && (queryForm.get('name').value ==  queryForm.get('currentName').value)">
              Query Name should be <strong>different</strong> than currrent name.
            </mat-error>

            <mat-form-field class="full-width-field" >
              <mat-label>Description</mat-label>
              <input matInput  formControlName="description" type="text" autofocus >
            </mat-form-field>
            <mat-error *ngIf="queryForm.get('description').hasError('required')">
              Description is <strong>required</strong>
            </mat-error>

            <mat-label>Is Public? </mat-label>
            <mat-slide-toggle formControlName="isPublic" ngDefaultControl color="primary"></mat-slide-toggle>

          </form>

          <mat-divider></mat-divider>
          <div class="alert" [ngClass]="alertType == 'success'  ? 'alert-success' : 'alert-danger' " style="margin-top: 5px; margin-bottom: 0px;" role="alert" *ngIf="message">
            <div [innerHTML]="message"></div>
          </div>
          <mat-dialog-actions align="end" class="full-width-field">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button color="primary" [disabled]="!isFormValid()" (click)="onAddUpdateQuery()" >{{action}}</button>
          </mat-dialog-actions>
        </ng-template>


        <ng-template [ngIf]="this.data?.action == 'DELETE'">
          <h1>Delete Query</h1>
          <hr />
          Are you sure you want to delete the select query:<br /><b> {{queryForm.get('name').value}}?</b>
          <mat-divider></mat-divider>
          <mat-dialog-actions align="end" class="full-width-field">
            <button mat-raised-button mat-dialog-close>No</button>
            <button mat-raised-button color="warn" (click)="onDeleteQuery()" >Yes</button>
          </mat-dialog-actions>
        </ng-template>
      </div>
    </div>
  </mat-card-content>
</mat-card>



