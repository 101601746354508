<ng-template [ngIf]="showSideBarColumnFilterView ">
  <p-sidebar [(visible)]="showSideBarColumnFilterView" (onHide)="onHide()" [style]="{width:'90%'}" [showCloseIcon]="false" position="right" [baseZIndex]="10000">
    <ng-template pTemplate="header">
      <div class="container">
        <div class="row row-cols-3">
          <div class="col h2">Select Columns for Excel</div>
          <div class="col text-center">
            <p-checkbox [(ngModel)]="checkedAll" [binary]="true" inputId="binary" (onChange)="onSelectAll()"
                        class="form-check-input p-checkbox-inline"></p-checkbox>&nbsp;
            <label for="binary">Select All</label>
            &nbsp;&nbsp;   &nbsp;&nbsp;
            <button mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px"
                    (click)="onDownload()">
              Download

            </button>
          </div>
          <div class="col text-end">
            <button mat-mini-fab color="primary" (click)="onHide()" matTooltip="Close window" aria-label="Close">
              <mat-icon>close</mat-icon>
            </button>
          </div>

        </div>
      </div>

    </ng-template>

    <ag-grid-angular style="width: auto; height: 00px;" [hidden]="true"
                     id="ag-Grid-{{reportType}}"
                     class="ag-theme-alpine"
                     [rowData]="rowData"
                     [columnDefs]="columnDefs"
                     [gridOptions]="gridOptions"
                     (gridReady)="onGridReady($event)"
                     [pagination]="false">
    </ag-grid-angular>

    <div class="card">
     
        <div class="card-body">

          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
             
              <mat-panel-title>
                <b>Narrative</b>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-row row">
              <div *ngFor="let item of columnDefsNarrative" class="col-md-4">
                <div class="form-check" *ngIf="item.headerName !== ''">
                  <p-checkbox name="filterColumns" value="item.headerName" [value]="item.headerName"
                              [(ngModel)]="filterColumns" (onChange)="setSelectAllChecked()"
                              inputId="item.headerName" 
                              class="form-check-input p-checkbox-inline"> </p-checkbox>&nbsp;
                  <label [for]="item.headerName">{{item.headerName}}</label>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <hr />
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>Aircraft</b>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-row row">
              <div *ngFor="let item of columnDefsAircraft" class="col-md-4">
                <div class="form-check" *ngIf="item.headerName !== ''">
                  <p-checkbox name="filterColumns" value="item.headerName" [value]="item.headerName"
                              [(ngModel)]="filterColumns" (onChange)="setSelectAllChecked()"
                              inputId="item.headerName"
                              class="form-check-input p-checkbox-inline"> </p-checkbox>&nbsp;
                  <label [for]="item.headerName">{{item.headerName}}</label>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <hr />
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>Incident Grid</b>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-row row">
              <div *ngFor="let item of columns" class="col-md-4">
                <div class="form-check" *ngIf="item.headerName !== ''">
                  <p-checkbox name="filterColumns" value="item.headerName" [value]="item.headerName"
                              [(ngModel)]="filterColumns" (onChange)="setSelectAllChecked()"
                              inputId="item.headerName"
                              class="form-check-input p-checkbox-inline"> </p-checkbox>&nbsp;
                  <label [for]="item.headerName">{{item.headerName}}</label>
                </div>
              </div>
            </div>
          </mat-expansion-panel>

        </div>
     
    </div>



  </p-sidebar>
</ng-template>
