import { Component, OnInit, ViewChild } from '@angular/core';

import { AgGridAngular } from 'ag-grid-angular';
import { ChartRef } from 'ag-grid-community';

import { ReportService } from '../services/report.service';

@Component({
  selector: 'app-report-pvn-total',
  templateUrl: './report-pvn-total.component.html',
  styleUrls: ['./report-pvn-total.component.css']
})
export class ReportPvNTotalComponent implements OnInit {
  error: string;

  result139: any = [];
  resultnon139: any = [];
  resultdetails: any = [];
  resultInjury: any = [];
  reportJSON: any = {
    "reportType": "",
    "report": ""
  };

  @ViewChild('agGrid') p139_agGrid: AgGridAngular;
  p139_gridColumnApi;
  p139_ColDef;
  p139_columnDefs;
  p139_gridApi;
  p139_autoGroupColumnDef;

  @ViewChild('agGrid_non139') non139_agGrid: AgGridAngular;
  non139_gridColumnApi;
  non139_ColDef;
  non139_columnDefs;
  non139_gridApi;
  non139_autoGroupColumnDef;

  p139currentChartRef: ChartRef = null;
  //public p139chartptions: AgCartesianChartOptions;

  non139currentChartRef: ChartRef = null;
  //public non139chartptions: AgCartesianChartOptions;


  constructor(public reportService: ReportService) {
    this.error = "";

    this.p139_columnDefs = [
      { field: 'type'},
      { field: 'value' }
    ];

    this.non139_columnDefs = [
      { field: 'type'},
      { field: 'value' }
    ];

  }

  ngOnInit(): void {
    this.loadReport('p139_total_events');
    this.loadReport('nonp139_total_events');
  }

  onp139GridReady(params) {
    try {
      this.p139_gridApi = params.api;
      this.p139_gridColumnApi = params.columnApi;
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  onnon139GridReady(params) {
    try {
      this.non139_gridApi = params.api;
      this.non139_gridColumnApi = params.columnApi;
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  onp139ModelUpdated(params) {
    try {
      if (this.p139currentChartRef != null)
        this.p139currentChartRef.destroyChart();
      var createRangeChartParams = {
        cellRange: {
          columns: ['type', 'value'],
        },

        chartType: 'groupedColumn', 
        chartContainer: document.querySelector('#p139Chart'),

        chartThemeOverrides: {
          common: {
            title: {
              enabled: true,
              text: 'Part 139',
              fontWeight: 'bold',
              fontSize: 16
            },
            width: 400,
            padding: {
              top: 20,
              left: 0,
              bottom: 0,
              right: 0,
            },
            legend: {
              enabled: false,
            },
          },
        },
      };

      this.p139currentChartRef = params.api.createRangeChart(createRangeChartParams);
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  onnon139ModelUpdated(params) {
    try {
      if (this.non139currentChartRef != null)
        this.non139currentChartRef.destroyChart();
      var createRangeChartParams = {
        cellRange: {
          columns: ['type', 'value'],
        },

        chartType: 'groupedColumn', 
        chartContainer: document.querySelector('#non139Chart'),

        chartThemeOverrides: {
          common: {
            title: {
              enabled: true,
              text: 'Non-Part 139',
              fontWeight: 'bold',
              fontSize: 16
            },
            width: 400,
            padding: {
              top: 20,
              left: 0,
              bottom: 0,
              right: 0,
            },
            legend: {
              enabled: false,
            },
          },
        },

      };

      this.non139currentChartRef = params.api.createRangeChart(createRangeChartParams);
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  loadReport(report) {
    try {
      this.reportJSON.reportType = report;
      this.reportJSON.report = report;
      this.reportService.getStatisticsReport(this.reportJSON).subscribe({
        next: (res: any) => {
          switch (report) {
            case 'p139_total_events':
              this.result139 = res.result;
              break;
            case 'nonp139_total_events':
              this.resultnon139 = res.result;
              break;
          }
        },
        error: (e) => {
          console.error("Ouch" + e);
        }
      });

    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }

  }

}
