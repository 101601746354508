import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AuthorizeService } from "../../api-authorization/authorize.service";
import { FAALocalStorageService } from "./localStorage.service";

@Injectable({
  providedIn: 'root'
})
export class MergeReportService {
  private apiEndpoint = 'api/MergeReport/';
  airports: any = {};
  constructor(private http: HttpClient, private authenticationService: AuthorizeService, private localStorageService: FAALocalStorageService) {

  }

  public getPotentialDuplicates(merged_data_source_id: any = '', startDate: any = '', endDate: any = ''): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'getPotentialDuplicates?Id=' + merged_data_source_id + '&startDate=' + startDate + '&endDate=' + endDate);
  }
  public GetReportDataForMergeById(id1: any = '', id2: any = ''): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'getReportDataForMergeById?Id1=' + id1 + '&Id2=' + id2);
  }
  //public MergeOrSetNotDuplicateReport(id1: any = '', id2: any = '', hasMerge: boolean = false, user: any = ''): Observable<any> {
  //  return this.http.post<any>(this.apiEndpoint + 'mergeOrSetNotDuplicateReport?Id1=' + id1 + '&Id2=' + id2 + '&hasMerge=' + hasMerge + '&user=' + user);
  //}
  public MergeOrSetNotDuplicateReport(id1: any = '', id2: any = '', hasMerge: boolean = false): Observable<any> {

    let vm = { id1: id1, id2: id2, hasMerge: hasMerge, user: this.authenticationService.getUserName() }
    return this.http.post<any>(this.apiEndpoint + 'MergeOrSetNotDuplicateReport/', vm);
  }
  public getMergedHistory(merged_data_source_id: any = ''): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + 'getMergedHistory?Id=' + merged_data_source_id);
  }
  public UnmergeReport(id: any = '', comment: any = ''): Observable<any> {

    let vm = { id: id, user: this.authenticationService.getUserName(), comment: comment }
    return this.http.post<any>(this.apiEndpoint + 'UnmergeReport/', vm);
  }
}




