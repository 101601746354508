<div *ngIf="error">
  <p>There was an error. Try again later.</p>
</div>
<div class="card" *ngIf="!error">
  <div class="card-header">
    <div class="row">
      <div class="col-md-3 h2">Statistical Reports</div>
     
      <div class="col-md-9 ">
        <mat-form-field appearance="fill" class="col-md-5">
          <mat-label>Date range(To and From)</mat-label>
          <mat-date-range-input [formGroup]="dateRangeReport" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker>
            <mat-date-range-picker-actions>
              <button mat-button matDateRangePickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDateRangePickerApply (click)="selectOption($event)">Apply</button>
            </mat-date-range-picker-actions>
            <mat-error *ngIf="dateRangeReport.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="dateRangeReport.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-date-range-picker>

        </mat-form-field>
        <mat-form-field appearance="fill" class="col-md-7">
          <mat-label>Select Report Type</mat-label>
          <mat-select placeholder="Select Report Type" [(ngModel)]="selected_report_options" (selectionChange)="selectOption($event)" name="reportType">
            <mat-option value="to_ld_collision">Collision with Obstacle During Takeoff or Landing Report</mat-option>
            <mat-option value="go_around_ri">Go-Around (Due to RIs Only) Report</mat-option>
            <mat-option value="ground_collision">Ground Collisions Report</mat-option>
            <mat-option value="loc">Loss of Control – Ground Report</mat-option>
            <mat-option value="nma">Non-Movement Area Events Report</mat-option>
            <mat-option value="run_inc_pd">Runway Incursion – PD Report</mat-option>
            <mat-option value="orun">Runway Overrun Report</mat-option>
            <mat-option value="run_undershoot">Runway Undershoot Report</mat-option>
            <mat-option value="veer">Runway Veer Report</mat-option>
            <mat-option value="surface_incident_pd">Surface Incidents – PD Report</mat-option>

          </mat-select>
        </mat-form-field>
      </div>
      </div>

    </div>
  <div class="card-body">
   
    <div class="row" style="border-bottom:medium">
      <div id="p139Chart" class="col" style="width: 30%; height: 300px;"></div>
      <div id="non139Chart" class="col" style="width: 30%; height: 300px;"></div>
      <div id="p139vsnonp139Chart" class="col" style="width: 30%; height: 300px;"></div>
     
    </div>
  
    <div class="row">
      <div id="partsChart" class="col" style="width: 30%; height: 300px;"></div>
      <div id="detailsChart" class="col" style="width: 30%; height: 300px;"></div>
      <div id="ARFChart" class="col" style="width: 20%; height: 300px; "></div>
      <div class="col">
        <table class="table table-sm table-bordered table-striped" style="width:200px;" *ngIf="resultInjury?.length >  0">
          <tr>
            <th>Injury Severity</th>
            <th>Count</th>
          </tr>

          <tr *ngFor="let injury of resultInjury;">
            <td>{{ injury.type }}</td>
            <td>{{ injury.value }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      
      
    </div>
    <div style="display: none">
      <ag-grid-angular id="p139Grid" style="width: auto; height: 450px;"
                       class="ag-theme-alpine"
                       [rowData]="result139"
                       [columnDefs]="p139_columnDefs"
                       [defaultColDef]="p139_ColDef"
                       (gridReady)="onp139GridReady($event)"
                       [animateRows]="true"
                       (modelUpdated)="onp139ModelUpdated($event)">
      </ag-grid-angular>

      <ag-grid-angular id="non139Grid" style="width: auto; height: 450px;"
                       class="ag-theme-alpine"
                       [rowData]="resultnon139"
                       [columnDefs]="non139_columnDefs"
                       [defaultColDef]="non139_ColDef"
                       (gridReady)="onnon139GridReady($event)"
                       [animateRows]="true"
                       (modelUpdated)="onnon139ModelUpdated($event)">
      </ag-grid-angular>

      <ag-grid-angular id="detailsGrid" style="width: auto; height: 450px; width: 60%"
                       class="ag-theme-alpine"
                       [rowData]="resultdetails"
                       [columnDefs]="details_columnDefs"
                       [defaultColDef]="details_ColDef"
                       (gridReady)="ondetailsGridReady($event)"
                       [animateRows]="true"
                       (modelUpdated)="ondetailsModelUpdated($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>

