
<div class="card-accordion">
  <mat-accordion multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          ASRS
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="card" *ngIf="showValueWithData(selectedRow?.asrs_rpt1_narrative)">
        <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT1 NARRATIVE:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.asrs_rpt1_narrative"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.asrs_rpt2_narrative)">
        <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT2 NARRATIVE:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.asrs_rpt2_narrative"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.asrs_result)">
        <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RESULT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.asrs_result"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.asrs_rpt1_callback)">
        <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT1 CALLBACK:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.asrs_rpt1_callback"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.asrs_rpt2_callback)">
        <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT2 CALLBACK:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.asrs_rpt2_callback"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.asrs_rpt1_synopsis)">
        <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT1 SYNOPSIS:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.asrs_rpt1_synopsis"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.asrs_problem)">
        <div class="card-header primary-header col-md-12 font-weight-bold">ASRS PROBLEM:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.asrs_problem"></span>
        </div>
      </div>

      <br />

    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          NTSB
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>


      <div class="card" *ngIf="showValueWithData(selectedRow?.ntsb_rprt_narr_prelim_text)">
        <div class="card-header primary-header font-weight-bold">NTSB RPRT NARR PRELIM TEXT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.ntsb_rprt_narr_prelim_text"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.ntsb_rprt_narr_cause_text)">
        <div class="card-header primary-header col-md-12 font-weight-bold">NTSB RPRT NARR CAUSE TEXT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.ntsb_rprt_narr_cause_text"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.ntsb_rprt_narr_faa_text)">
        <div class="card-header primary-header col-md-12 font-weight-bold">NTSB RPRT NARR FAA TEXT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.ntsb_rprt_narr_faa_text"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.ntsb_rprt_narr_final_text)">
        <div class="card-header primary-header col-md-12 font-weight-bold">NTSB RPRT NARR FINAL TEXT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.ntsb_rprt_narr_final_text"></span>
        </div>
      </div>
      <br />

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          RWS
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="card" *ngIf="showValueWithData(selectedRow?.rws_rwy_sfty_narrative)">
        <div class="card-header primary-header col-md-12 font-weight-bold">RWS RWY SFTY NARRATIVE:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.rws_rwy_sfty_narrative"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.rws_rwy_sfty_asmnt_rmk)">
        <div class="card-header primary-header col-md-12 font-weight-bold">RWS RWY SFTY ASMNT RMK:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.rws_rwy_sfty_asmnt_rmk"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.rws_rwy_sfty_narrative)">
        <div class="card-header primary-header col-md-12 font-weight-bold">RWS ORIGINAL NARRATIVE:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.rws_original_narrative"></span>
        </div>
      </div>

      <br />


    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          PDS
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>


      <div class="card" *ngIf="showValueWithData(selectedRow?.pds_corr_rmk_text)">
        <div class="card-header primary-header col-md-12 font-weight-bold">PDS CORR RMK TEXT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.pds_corr_rmk_text"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.pds_prelim_rmk_text)">
        <div class="card-header primary-header col-md-12 font-weight-bold">PDS PRELIM RMK TEXT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.pds_prelim_rmk_text"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.pds_inves_rmk_text)">
        <div class="card-header primary-header col-md-12 font-weight-bold">PDS INVES RMK TEXT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.pds_inves_rmk_text"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.pds_entry_rmk_text)">
        <div class="card-header primary-header col-md-12 font-weight-bold">PDS ENTRY RMK TEXT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.pds_entry_rmk_text"></span>
        </div>
      </div>

      <br />
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          VPDS
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="card" *ngIf="showValueWithData(selectedRow?.vpd_rmk_text_entry)">
        <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT ENTRY:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.vpd_rmk_text_entry"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.vpd_rmk_text_inves)">
        <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT INVES:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.vpd_rmk_text_inves "></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.vpd_rmk_text_narrat)">
        <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT NARRAT:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.vpd_rmk_text_narrat"></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.vpd_rmk_text_prelim)">
        <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT PRELIM:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.vpd_rmk_text_prelim "></span>
        </div>
      </div>

      <div class="card" *ngIf="showValueWithData(selectedRow?.vpd_rmk_text_corr)">
        <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT CORR:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.vpd_rmk_text_corr"></span>
        </div>
      </div>
      <br />
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          AIDS
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="card" *ngIf="showValueWithData(selectedRow?.aid_rmk_text)">
        <div class="card-header primary-header font-weight-bold">AIDS Remarks Text:</div>
        <div class="card-body">
          <span [innerHTML]="selectedRow?.aid_rmk_text"></span>
        </div>
      </div>
      <br />
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Analyst Notes
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="card" *ngIf="showValueWithData(selectedRow?.notes)">
        <div class="card-body"> <span [innerHTML]="selectedRow?.notes "></span></div>
      </div>
      <br />

    </mat-expansion-panel>
  </mat-accordion>

</div>
