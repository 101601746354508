
<div class="container-fluid">

  <mat-card class="asd-card">

    <mat-card-header>

      <mat-card-title>
        <h1>{{title}}</h1>
      </mat-card-title>
      <hr>
    </mat-card-header>

    <mat-card-content>
      <ng-template [ngIf]="true" class="container-fluid statistic-count">
        <div>
          <div class="card-header">
            <b>Search Parameter(s)</b>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <mat-form-field appearance="fill" class="col-md-10">
                  <mat-label>Date range(To and From)</mat-label>
                  <mat-date-range-input [rangePicker]="picker"  [formGroup]="dateRangeReport">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date" >
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="fill" class="col-md-12">
                  <mat-label>
                    Incident ID
                  </mat-label>
                  <input matInput [(ngModel)]="merged_data_source_id" name="merged_data_source_id" />
                </mat-form-field>
              </div>

              <div class="col-md-5">
                <button mat-raised-button class="btn-primary" (click)="loadReportData()">Search</button>&nbsp;&nbsp;
                <button mat-raised-button (click)="onClearInput()">Clear</button>
              </div>
            </div>
          </div>
        </div>


        <div class="card">

          <div class="card-header primary-header">
            <div class="row text-ce">
              <div class="col-sm-6"><b>Report Data</b> </div>
              <div class="col-sm-6 text-end">
                <button mat-raised-button class="btn btn-outline-primary " (click)="onDownload()" matTooltip="Click to download current grid view data" [disabled]="!hasGridData">Download</button>
              </div>
            </div>
          </div>
          <div class="card-body card-body-usda-table">
            <div class="table-responsive " [hidden]="!hasGridData">
              <div class="row">
                <div class="col-md-12">
                  <ag-grid-angular style="width: auto; height: 450px;" id="myGrid"
                                   class="ag-theme-alpine"
                                   [rowData]="rowData"
                                   [columnDefs]="columnDefs"
                                   [gridOptions]="gridOptions"
                                   [defaultColDef]="defaultColDef"
                                   (gridReady)="onGridReady($event)"
                                   [pagination]="true"
                                   [paginationPageSize]="10"
                                   [autoGroupColumnDef]="autoGroupColumnDef"
                                   (firstDataRendered)="onFirstDataRendered($event)"
                                   [animateRows]="true">
                  </ag-grid-angular>
                </div>
              </div>
            </div>

            <div *ngIf="message && hasGridData == 0">
              {{message}}
            </div>
            <div *ngIf="!message && hasGridData == 0">
              Please use search input and click search button to load report.
            </div>
          </div>
        </div>

      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
<app-sidebar-merge-view [type]="'MERGE'"
                        [reportType]="reportType"
                        [originalReportNo]="originalReportNo"
                        [duplicateReportNo]="duplicateReportNo"
                        [showMergedSideBarView]="showMergedSideBarView" [baseZIndex]="10000"
                        (onCloseSideBarMergeView)="onCloseSideBarMergeView($event)">

</app-sidebar-merge-view>
