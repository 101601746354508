
<div class="content container">

  <mat-card class="asd-card">

    <div class="alert alert-info" role="alert" *ngIf="homeAnnouncement">
      <i class="fa fa-bullhorn" aria-hidden="true"></i> &nbsp; &nbsp;
      <span [innerHTML]="homeAnnouncement"></span>
    </div>
    <mat-card-header>

      <mat-card-title>
        <h1>{{title}}</h1>
      </mat-card-title>
      <hr>
    </mat-card-header>

    <mat-card-content>

      <div class="container">
        <p>
          The <b>Airport Safety Database (ASD)</b> has been developed to use in the analysis of data to identify causal factors in incidents and accidents that have occurred on or near airports. This data is used to produce quarterly and annual reports to identify airports and/or types of events that require attention/action by the FAA Office of Airports.
        </p>
        <div class="row">
          <div class="d-grid col-4">
            <a role="button" class="btn btn-primary btn-lg btn-block btn-home-link" [routerLink]="'/report'" [queryParams]="{type: 'AIRPORT'}" *ngIf="((role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')) &&  (isAuthenticated  | async)">
              Airport Searches
            </a>
            <a role="button" class="btn btn-primary btn-lg btn-block btn-home-link" (click)="onOpenAuthorizedAccessDialog()" *ngIf="(role === 'PUBLIC' || role === '') || !(isAuthenticated  | async)">
              Airport Searches
            </a>
          </div>
          <div class="d-sm-none" style="padding: 1px;"></div>
          <div class="d-grid col-4">
            <a role="button" class="btn btn-primary btn-lg btn-block btn-home-link" [routerLink]="'/report'" [queryParams]="{type: 'INCIDENT'}" *ngIf="((role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')) &&  (isAuthenticated  | async)">
              Incident Searches
            </a>
            <a role="button" class="btn btn-primary btn-lg btn-block btn-home-link" (click)="onOpenAuthorizedAccessDialog()" *ngIf="(role === 'PUBLIC' || role === '') || !(isAuthenticated  | async)">
              Incident Searches
            </a>
          </div>
          <div class="d-sm-none" style="padding: 1px;"></div>
          <div class="d-grid col-4">
            <a role="button" class="btn btn-primary btn-lg btn-block btn-home-link" [routerLink]="'/report'" [queryParams]="{type: 'NTSB'}" *ngIf="((role === 'ADMIN' || role === 'REVIEWER' || role === 'VIEWER')) &&  (isAuthenticated  | async)">
              Data Source Searches
            </a>
            <a role="button" class="btn btn-primary btn-lg btn-block btn-home-link" (click)="onOpenAuthorizedAccessDialog()" *ngIf="(role === 'PUBLIC' || role === '') || !(isAuthenticated  | async)">
              Data Source Searches
            </a>
          </div>
        </div>
        <br />
        <p>The <b>Airport Safety Database (ASD)</b> allows for searches on 20 categorizations of data. These search categories include: </p>
        <div class="row">
          <div class="col">
            <ul class="list-group asd-list-group-font-13">
              <li class="list-group-item">Date range</li>
              <li class="list-group-item">Airport</li>
              <li class="list-group-item">Location (e.g. runway, taxiway)</li>
              <li class="list-group-item">Weather phenomena (snow, ice, etc)</li>
              <li class="list-group-item">Phase of flight</li>
              <li class="list-group-item">Latitude/longitude</li>
              <li class="list-group-item">Notes</li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-group asd-list-group-font-13">
              <li class="list-group-item">Time range</li>
              <li class="list-group-item">Airport type (e.g. 139, public use)</li>
              <li class="list-group-item">Occurrence type (e.g accident, runway incursion)</li>
              <li class="list-group-item">Surface condition</li>
              <li class="list-group-item">Category of runway incursion (A, B, C, D or E)</li>
              <li class="list-group-item">Level of damage</li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-group asd-list-group-font-13">
              <li class="list-group-item">Time of day (day or night)</li>
              <li class="list-group-item">Data source</li>
              <li class="list-group-item">Meteorological condition (VMC, IMC)</li>
              <li class="list-group-item">Type of operation (e.g. Part 91, 121 etc)</li>
              <li class="list-group-item">Key words, hot spot occurrence</li>
              <li class="list-group-item">Injuries/fatalities</li>
            </ul>


          </div>
        </div>
        <p>
          <br>
          Airport diagrams are included for all in-scope airports. There are over <b>{{airport_count}}</b> in-scope airports included in the <b>Airport Safety Database (ASD)</b>.
        </p>
        <br />
        <p><b>Airport Safety Database (ASD)</b> Release 2.3.10 contains six sources of data: </p>
        <div class="row">
          <div class="col">
            <ul class="list-group asd-list-group-font-13">
              <li class="list-group-item">AIDS - FAA Accident/Incident database.</li>
              <li class="list-group-item">NTSB - National Transportation Safety Board Aviation Accident and Incident Data System</li>
              <li class="list-group-item">RWS - FAA Runway Safety Office Runway Incursion Database</li>

            </ul>
          </div>
          <div class="col">
            <ul class="list-group asd-list-group-font-13">
              <li class="list-group-item">ASRS - NASA Aviation Safety Reporting System</li>
              <li class="list-group-item">PDS - FAA Pilot Deviation System</li>
              <li class="list-group-item">VPDS - FAA Vehicle/Pedestrian Deviation System.</li>

            </ul>
          </div>

        </div>
      </div>
    </mat-card-content>
  
  </mat-card>

</div>
