import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild, inject, forwardRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormsModule, ReactiveFormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LiveAnnouncer } from '@angular/cdk/a11y';

export interface Item {
  id: number;
  item: string;
  code: string;
  name: string;
  selected: boolean;
}
@Component({
  selector: 'app-faa-multiselect-autocomplete',

  templateUrl: './faa-multiselect-autocomplete.component.html',
  styleUrl: './faa-multiselect-autocomplete.component.css',
   providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FaaMultiselectAutocompleteComponent),
    multi: true
  }]
})
export class FaaMultiselectAutocompleteComponent implements ControlValueAccessor,OnInit {

  @Output() result = new EventEmitter<{ key: string, data: Array<string> }>();
  itemControl = new FormControl('');
  @Input() itemControlName: string = 'ctr_name';
  @Input() placeholder: string = 'Select..';
  @Input() field_label: string = 'Control_Label';
  @Input() field_class: string = '3';
  @Input() allItems: Item[] =[];
  @Input() selectedItems: Item[] = [];
  @Input() data: Array<string> = [];
  @Input() key: string = 'code';
  @Output() selectionChange = new EventEmitter <{ selectedItems: Item[], control: string }>();
  
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredItems: Observable<Item[]>;
  onChange: (items: Item[]) => void = () => { };
  onTouched: () => void = () => { };
  constructor() {
    //this.filteredItems = this.itemControl.valueChanges.pipe(
    //  startWith(null),
    //  map((item: string | null) => (item ? this._filter(item) : this.allItems.slice())),
    //);
  }
  ngOnInit() {
   
    this.filteredItems = this.itemControl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => item ? this._filter(item) : this.allItems));
    
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();
    if ((value || '').trim()) {
      const selectedItem = this.allItems.find(item => item[this.key].toLowerCase() === value.trim().toLowerCase());
      if (selectedItem && !this.selectedItems.includes(selectedItem)) {
        this.selectedItems.push(selectedItem);
        this.onChange(this.selectedItems);
        this.selectionChange.emit({ selectedItems: this.selectedItems, control: this.itemControlName });
      }
    }
    if (input) {
      input.value = '';
    }
    this.itemControl.setValue(null);
  }

  remove(item: Item): void {
    const index = this.selectedItems.indexOf(item);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.onChange(this.selectedItems);
      this.selectionChange.emit({ selectedItems: this.selectedItems, control: this.itemControlName });
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
  
    const selectedItem = this.allItems.find(item => item[this.key] === event.option.value);
   
    if (selectedItem && !this.selectedItems.includes(selectedItem)) {
      this.selectedItems.push(selectedItem);
     // this.itemInput.nativeElement.value = '';
      this.onChange(this.selectedItems);
      this.selectionChange.emit({ selectedItems: this.selectedItems, control: this.itemControlName });
    }
    this.itemControl.setValue(null);
  }

   _filter(value: string): Item[] {
    const filterValue = value.toLowerCase();
    return this.allItems.filter(item => item[this.key].toLowerCase().includes(filterValue));

  }
  writeValue(value: Item[]): void {
    //if (value) {
    //  this.selectedItems = [];
    //}
  }
  registerOnChange(fn: (items: Item[]) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.itemControl.disable();
    } else {
      this.itemControl.enable();
    }
  }
}
