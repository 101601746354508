import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorizeService } from "../../api-authorization/authorize.service";
import { FAALocalStorageService } from "./localStorage.service";

@Injectable({
  providedIn: 'root'
})
export class AnalystDeliverableReportService {
  private apiEndpoint = 'api/AnalystDeliverable/';
  constructor(private http: HttpClient, private authenticationService: AuthorizeService, private localStorageService: FAALocalStorageService) {

  }
  public getAnalystDeliverableReport(type: any, query: any, keyword: any = '', maxRows: number = 0, id: any = '', startDate: any = '', endDate: any = ''): Observable<any> {
    if (!maxRows)
      maxRows = 0;
    query = encodeURIComponent(query);
    return this.http.get<any>(this.apiEndpoint + 'GetAnalystDeliverableReport?type=' + type + '&q=' + query + '&keyword=' + keyword +
      '&maxRows=' + maxRows + '&id=' + id + '&startDate=' + startDate + '&endDate=' + endDate);

  }
  public getIngestStatistics(): Observable<any> {

    return this.http.get<any>(this.apiEndpoint + 'GetIngestStatistics');
  }

}
