<mat-form-field appearance="fill" class="col-md-{{field_class}}" style="padding-left:0px;" >
  <mat-label>{{field_label}}</mat-label>
  <mat-chip-grid #chipGrid aria-label="item selectedItems">
    @for (item of selectedItems; track item) {
    <mat-chip-row (removed)="remove(item)">
      {{item[key]}}
      <button matChipRemove [attr.aria-label]="'remove'">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    }
  </mat-chip-grid>
  <input placeholder="{{placeholder}}" #itemInput [formControl]="itemControl"
         [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
         (matChipInputTokenEnd)="add($event)" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    @for (item of filteredItems | async; track item) {
    <mat-option [value]="item[key]">{{item[key]}} - {{item.name}}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
